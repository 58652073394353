import React from 'react';
import styled from 'styled-components';
import FirstPageIcon from '../../../../resources/img/icon-first-page.png';
import LastPageIcon from '../../../../resources/img/icon-last-page.png';
import PreviousPageIcon from '../../../../resources/img/icon-previous-page.png';
import NextPageIcon from '../../../../resources/img/icon-next-page.png';
import { defaultTransition } from '../../../../resources/style/functions';

const PageSelectorWrapper = styled.div`
    display: flex;
    align-items: center;
`

const SelectorButtonsWrapper = styled.div`
    margin: 0 10px;
`

const SelectorButton = styled.button`
    width: 22px;
    height: 22px;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    border-radius: 50%;
    outline:none;
    color: #ffffff;
    background: #2d7865;
    cursor: pointer;
    border: 2px solid #2d7865;
    ${defaultTransition};
    &:not(:last-child) {
        margin-right: 10px;
    }
    &:hover{
        background: #7cb63d;
        border-color: #7cb63d;
    }
    ${({active})=>{
       return active
    ?`
        background: #8ac53d;
        border-color: #2d7865;
    `:''}
    }
    &[disabled] {
        background: ${props => props.theme.buttonBackgroundDisabled};
        cursor: not-allowed;
        box-shadow: none;
    }
`

const NavigationButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    border:none;
    background:none;
    cursor: pointer;
    &:first-child {
        margin-right: 20px;
    }
    &:last-child {
        margin-left: 20px;
    }    
    &[disabled] {        
        cursor: not-allowed;
        box-shadow: none;
    }
`

const PaginationIcon = styled.img`
    height:15px;
`

const PageSelector = (props)=>{
    const {count, onChangePage, page, rowsPerPage} = props;
    
    let maxPage = Math.ceil(count/rowsPerPage);
    
    let pageIndexes =[];
    let pageButtons = null;
    
    if(maxPage<8){
        for(let i=1;i<maxPage+1;i++){
            pageIndexes.push(i);
        }
        pageButtons = 
                <span>
                    {pageIndexes.map((index)=>(<SelectorButton key={index} active={page===index-1} onClick={()=>onChangePage(index-1)}>{index}</SelectorButton>))}                    
                </span>;
    }
    else{
        if(page<4){
            //neophodno dodati -1 da bi se sve spustilo na 0 start            
            let N=4;
            pageButtons = 
                <span>
                    <SelectorButton onClick={()=>onChangePage(N-3-1)} active={page===(N-3-1)}>{N-3}</SelectorButton>
                    <SelectorButton onClick={()=>onChangePage(N-2-1)} active={page===(N-2-1)}>{N-2}</SelectorButton>
                    <SelectorButton onClick={()=>onChangePage(N-1-1)} active={page===(N-1-1)}>{N-1}</SelectorButton>
                    <SelectorButton onClick={()=>onChangePage(N-1)} active={page===(N-1)}>{N}</SelectorButton>
                    <SelectorButton onClick={()=>onChangePage(N+1-1)} active={page===(N+1-1)}>{N+1}</SelectorButton>
                    <SelectorButton onClick={()=>onChangePage(N+2-1)} active={page===(N+2-1)}>{N+2}</SelectorButton>
                    <SelectorButton onClick={()=>onChangePage(N+3-1)} active={page===(N+3-1)}>{N+3}</SelectorButton>
                </span>

        }
        else{

            let N;
            if(page+1>maxPage-3){
                N=maxPage-3;
            }else{
                N=page+1;
            }
            pageButtons = 
                    <span>
                        <SelectorButton onClick={()=>onChangePage(N-3-1)} active={page===(N-3-1)}>{N-3}</SelectorButton>
                        <SelectorButton onClick={()=>onChangePage(N-2-1)} active={page===(N-2-1)}>{N-2}</SelectorButton>
                        <SelectorButton onClick={()=>onChangePage(N-1-1)} active={page===(N-1-1)}>{N-1}</SelectorButton>
                        <SelectorButton onClick={()=>onChangePage(N-1)} active={page===(N-1)}>{N}</SelectorButton>
                        <SelectorButton onClick={()=>onChangePage(N+1-1)} active={page===(N+1-1)}>{N+1}</SelectorButton>
                        <SelectorButton onClick={()=>onChangePage(N+2-1)} active={page===(N+2-1)}>{N+2}</SelectorButton>
                        <SelectorButton onClick={()=>onChangePage(N+3-1)} active={page===(N+3-1)}>{N+3}</SelectorButton>
                    </span>
            
        }
    }    
    return(
        <PageSelectorWrapper>
            <NavigationButton onClick={()=>onChangePage(0)} disabled={page===0}>
                <PaginationIcon src={page===0?PreviousPageIcon:FirstPageIcon} alt=""/>
            </NavigationButton>

            <NavigationButton onClick={()=>onChangePage(page-1)} disabled={page===0}>
            <PaginationIcon src={page===0?FirstPageIcon:PreviousPageIcon} alt=""/>
            </NavigationButton>

            <SelectorButtonsWrapper>
                {pageButtons}
            </SelectorButtonsWrapper>

            <NavigationButton onClick={()=>onChangePage(page+1)} disabled={page+1===maxPage}>
                <PaginationIcon src={page+1===maxPage?LastPageIcon:NextPageIcon} alt=""/>
            </NavigationButton>

            <NavigationButton onClick={()=>onChangePage(maxPage-1)} disabled={page+1===maxPage}>
                <PaginationIcon src={page+1===maxPage?NextPageIcon:LastPageIcon} alt=""/>
            </NavigationButton>
        </PageSelectorWrapper>
    );
}
export default PageSelector;