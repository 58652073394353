import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
    ${({ width='24', margin='0', fill='#000' }) => `
        width: ${width};
        margin: ${margin};
        fill: ${fill};
    `}
`;

const VideoIcon = ({ width, margin, fill }) => {
    return (
        <Svg width={width} margin={margin} fill={fill} height="20" viewBox="0 0 24 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 10C16 11.104 15.104 12 14 12H2C0.896 12 0 11.104 0 10V2C0 0.896 0.896 0 2 0H14C15.104 0 16 0.896 16 2V10ZM24 0L18 4.223V7.777L24 12V0Z" />
        </Svg>
    )
}

export default VideoIcon;
