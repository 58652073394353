import React from 'react';
// import styled from 'styled-components';
// import {NavContextConsumer} from '../../../utility/Contexts/NavigationContext';
import Button from '../Button/Button';

// const Icon = styled.img`
//     margin-right: 10px;
//     margin-top: 1px;
//     height: 18px;
// `

const RowActions = ({onClick, actionButtonText} ) => (  
            <React.Fragment>
                <Button onClickHandler={() => onClick()}>{actionButtonText}</Button>               
            </React.Fragment>           
);

export default RowActions;