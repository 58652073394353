import React, { Component } from 'react';
import styled from 'styled-components';
import { globalVariables } from "../../utility/common";
import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';

import { Frame, Input, Button, DataTable, FormManager, MessageBox } from "../../components/ui";
import LoadingSpinner from '../../components/ui/LoadingSpinner/LoadingSpinner';
import IconBack from '../../resources/img/icon-back.png';

import IconSearch from "../../resources/img/icon-search.png";
import { AdminContext } from '../../utility/Contexts/AdminContext';


const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - ${globalVariables.headerHeight});
    padding: 40px 40px 0;
    background: #ecf1eb;
`;

const CrudWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const BackIcon = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    border: 1px solid #2D7865;
    border-radius: 50%;
    cursor: pointer;
`

const tableFields = [
    {
        fieldId:'id',
        displayName: 'Id',
        isDefaultSort:true,
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'name',
        displayName: 'Name',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'surname',
        displayName: 'Surname',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'institution',
        displayName: 'Institution',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'email',
        displayName: 'Email',                    
        isSortable:true,
        isFilterable:true
    },
];

const formFields=[
    {
        id: 'email',
        inputType: 'text',
        value:'',
        label: 'email',
        autofocus: true,
        validation:{
            required:true,
            minLength:2,
            maxLength:50,
            isEmail: true
        }
    },
    {
        id: 'name',
        inputType: 'text',
        value:'',
        label: 'name',
        validation:{
            required:true,
            minLength:2,
            maxLength:50
        }
    },
    {
        id: 'surname',
        inputType: 'text',
        value:'',
        label: 'surname',
        validation:{
            required:true,
            minLength:2,
            maxLength:50
        }
    },
    {
        id: 'institution',
        inputType: 'text',
        value:'',
        label: 'institution',
        validation:{
            required:false,
            minLength:2,
            maxLength:50
        }
    },
]

const searchHelper = (users,search)=>{    
    if(!users) return [];
    if(users.length===0) return users;
    if(search.length===0) return users;
    const searchData = users.filter(d=>d.email.toLowerCase().includes(search.toLowerCase()));
    return searchData
}
class AdminPanel extends Component {

    async componentWillMount(){
       await this.context.getData();      
    }

    state = {
        activeForm: null,
        selectedUser: '',
        search:""
    }

    handleOpenForm = ()=>{
        this.setState({
            ...this.state,
            activeForm : <FormManager title="ADD USER" onCancel={this.handleCloseForm} onFinish={this.handleFormFinish} fields={JSON.parse(JSON.stringify(formFields))}/>
        })    
    }

    handleOpenEditForm = ()=>{
        if(this.state.selectedUser.length===0) return;
        const userIndex = this.context.users.findIndex(l=>l.id === this.state.selectedUser);
        if(userIndex<0) return;
        const user = this.context.users[userIndex];
        const userFields= JSON.parse(JSON.stringify(formFields));
        
        userFields.forEach(field => {
            if(user.hasOwnProperty(field.id)){
                field.value = user[field.id];
            }
        });
        this.setState({
            ...this.state,
            selectedUser:user.id,
            activeForm : <FormManager title="EDIT USER" edit onCancel={this.handleCloseForm} onFinish={this.handleEditFormFinish} fields={JSON.parse(JSON.stringify(userFields))}/>
        })
    }

    handleCloseForm = () => {
        this.setState({
            ...this.state,
            activeForm:null,
            selectedLocation:"",
        })
    }

    handleFormFinish = async(user) => {
        const data = {};
        user.forEach(usr => {
            if(usr.id==="institution")
            {    
                if(usr.value!=='')
                data[usr.id] = usr.value;
            }
            else{
                data[usr.id] = usr.value;
            }
        });
        
        await this.context.insertUser(data);
        this.handleCloseForm();
    }

    handleEditFormFinish = async(user) =>{
        const data = {};
        user.forEach(usr => {
            if(usr.id==="institution")
            {    
                if(usr.value!=='')
                data[usr.id] = usr.value;
            }
            else{
                data[usr.id] = usr.value;
            }
        });
        data.id=this.state.selectedUser;
        await this.context.updateUser(data);
        this.handleCloseForm();
    }

    handleConfirmMessageBox = ()=>{
        this.context.deleteUser(this.state.selectedUser);
        
        this.setState({
            ...this.state,
            activeForm:null,
            selectedUser:""
        })
    }

    handleOpenMessageBox = (locationId)=>{
        this.setState({
            ...this.state,
            selectedLocation:locationId,
            activeForm: <MessageBox onCancel={this.handleCloseMessageBox} onConfirm={this.handleConfirmMessageBox} title="Confirm Delete" confirmButtonText='Delete'>Are you sure that you wish to delete this user?</MessageBox>
        })
    }

    handleCloseMessageBox = ()=>{
        this.setState({
            ...this.state,
            activeForm:null
        })
    }

    handleTableRowSelected = (id)=>{
        this.setState({
            ...this.state,
            selectedUser:id
        })
    }

    handleSearch = (e) =>{
        
        this.setState({
            ...this.state,
            search:e.currentTarget.value
        })
    }
    render() {
        const { activeForm, selectedUser,search } = this.state;
        const {dataLoading, users, mapUrl, hasError, errorMessage, handleRemoveError} = this.context;
        const tableColumnWidths =   <colgroup>
                                        <col style={{width: "70px"}} />
                                        <col style={{width: "300px"}} />
                                        <col style={{width: "300px"}} />
                                        <col style={{width: "100%"}} />
                                        <col style={{width: "400px"}} />
                                    </colgroup>;
        
        if(dataLoading) return <LoadingSpinner/>
        const dataToShow = searchHelper(users,search); 
        return (
            <PageWrapper>
                <Frame title='Users'>
                    <Input placeholderText="Search by Email" icon={IconSearch} margin="0 0 14px 0" value={search} onTextInput={this.handleSearch}/>
                    <CrudWrapper>
                            <Button margin="0 20px 10px 20px" onClickHandler={() => this.handleOpenForm()}>ADD USER</Button>
                            <Button margin="0 20px 10px 0" disabled={selectedUser.length === 0} onClickHandler={() => this.handleOpenEditForm()}>EDIT</Button>
                            <Button margin="0 0 10px 0" disabled={selectedUser.length === 0} onClickHandler={() => this.handleOpenMessageBox()}>DELETE</Button>
                    </CrudWrapper>
                    <DataTable columnWidths={tableColumnWidths}
                                maxRows={10}
                                tableFields={tableFields}
                                data={dataToShow}
                                onDoubleClickHandler={()=>{}}
                                onClickHandler={this.handleTableRowSelected}
                                activeRow={selectedUser}
                                primaryKey='id'/>
                </Frame>

                <Frame title='Map Source'>
                    <Input placeholderText="Search" margin="0 0 20px 0" value={mapUrl} readOnly/>
                    <Button margin='0 0 0 20px' onClickHandler={()=>{}} disabled>Apply</Button>
                </Frame>
                <NavContextConsumer>
                        {({ onItemChange }) =>
                            <BackIcon onClick={() => onItemChange({id: 'locationViewer', pageName: 'Locations View'})}>
                                <img src={IconBack} alt=""/>
                            </BackIcon>
                        }
                    </NavContextConsumer>
                {activeForm}
                {hasError ? <MessageBox title="Error" confirmButtonText='Close' onConfirm={handleRemoveError}>{errorMessage}</MessageBox> : null}
                {dataLoading ? <LoadingSpinner /> : null}
            </PageWrapper>
        )
    }
};

AdminPanel.contextType = AdminContext;

export default AdminPanel;