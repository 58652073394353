import React from 'react';
import styled from 'styled-components';

const StyledFieldset = styled.fieldset`
    position: relative;
    width: 100%;
    ${({ height = 'auto', margin = '0', padding = '0 8px 8px 8px', borderColor = '#2D7865' }) => `
        height: ${height};
        margin: ${margin};
        padding: ${padding};
        border-color: ${borderColor};
    `}
    border-width: 1px;
    border-style: solid;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
`;

const StyledLegend = styled.legend`
    display: flex;
    align-items: center;
    margin-left: 32px;
    padding: 0 10px;
    ${({textColor = '#2D7865'}) => `
        color: ${textColor};
    `}
`;

const Frame = ({ title = "text placeholder", height, margin, padding, borderColor, textColor, icon = null, children = null }) => {
    return (
        <StyledFieldset height={height} margin={margin} padding={padding} borderColor={borderColor}>
            <StyledLegend textColor={textColor}>{title}{icon}</StyledLegend>
            {children}
        </StyledFieldset>
    );
};

export default Frame;