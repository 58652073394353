import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    padding: 8px 8px 0;
    border-radius: 5px;
    background: #ffffff;
`;

const TableWrapper = ({children=null})=>{
    return(
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default TableWrapper;