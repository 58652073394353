import React from 'react';
import styled from 'styled-components';
import IconUploadPhoto from "../../../resources/img/icon-photo-upload.png";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    height: calc(100% - 90px);
    background: #ECF1EB;
`;

const HidenFileInput = styled.input`
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

const UploadButton = styled.label`
    min-width: 196px;
    height: 74px;
    line-height: 74px;
    font-size: 30px;
    text-align: center;
    letter-spacing: -1.5px;
    color: #ffffff;
    background-color: #59c9e5;
    border: none;
    border-radius: 37px;
    outline: none;
    cursor: pointer;
`;

const Icon = styled.img`
    margin-bottom: 56px;
`;

const Text = styled.p`
    margin-bottom: 42px;
    width: 361px;
    font-size: 20px;
    text-align: center;
    color: rgba(45, 120, 101, 0.5);

`;

const UploadImage = ({ handleImageUpload = () => {console.log('Uploaded!')} }) => (
    <Wrapper>
        <Icon src={IconUploadPhoto} />
        <Text>Upload the image from your computer</Text>
        <HidenFileInput id='file-input' type='file' onChange={e => handleImageUpload(e.target.files)} />
        <UploadButton htmlFor='file-input'>Upload</UploadButton>
    </Wrapper>
);

export default UploadImage;
