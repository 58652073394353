import React, {PureComponent } from 'react';
import {TableWrapper, Table, TableHeader, TableBody, TableHeaderRow,TableBodyRow, TableCell, Pagination } from '.';
import {RowActions} from '../';
import styled from 'styled-components';
import IconSortAsc from '../../../resources/img/icon-sort-ascending.png';
import IconSortDesc from '../../../resources/img/icon-sort-descending.png';
import IconSortNone from '../../../resources/img/icon-sort-inactive.png';

let dataCount = 0; 
function dynamicSort(direction,field){
    let sortOrder;
    if(direction==="asc"){
        sortOrder = 1;
    }
    else{
        sortOrder = -1
    }
    return function (a,b) {
        var result = (a[field] < b[field]) ? -1 : (a[field] > b[field]) ? 1 : 0;
        return result * sortOrder;
    }
}

function dataPreparationHandler(items,page,rowsPerPage,orderBy,orderDirection){
    let preparedData = [...items];
    dataCount = preparedData.length;
    const sortData = preparedData.sort(dynamicSort(orderDirection,orderBy));
    return sortData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

const SortIcon = styled.img`
    display:inline-block;
    height:17px;
    margin-left:10px;
`;

class DataTable extends PureComponent{
    state={        
        page:0,
        rowsPerPage:this.props.maxRows,
        itemsToDisplay:[10,20,50],
        sortingItem:"none",
        sortingDirection:"none"
    };

    handleSort=(id)=>{
        if(this.state.sortingItem===id){
            //if equal change direction
            switch(this.state.sortingDirection){
                case "asc":{
                    this.setState({
                        ...this.state,
                        sortingDirection:"desc"
                    });
                    break;
                }
                default:
                case "desc":{
                    this.setState({
                        ...this.state,
                        sortingDirection:"none",
                        sortingItem:"none"
                    });
                    break;
                }
            }
        }
        else{
            //if not, change item and set direction to asc
            this.setState({
                ...this.state,
                sortingItem:id,
                sortingDirection:"asc"
            })
        }        
    }

    handleChangeRowsPerPage = event => {        
        this.setState({ rowsPerPage: ~~event.target.value,
        page:0 });     
    };

    handleChangePage = page =>{
        this.setState({ page: ~~page});       
    };
    
    render(){
        const{page, rowsPerPage, itemsToDisplay, sortingItem, sortingDirection} = this.state;
        const{tableFields, data, primaryKey, onDoubleClickHandler, onClickHandler, activeRow, actionButton, point, columnWidths=null, contentTypes} = this.props;
        let proItems = []
        if(data.length>0){
        //handle sort

        //hadle pagination
            proItems = dataPreparationHandler(data,page,rowsPerPage,sortingItem,sortingDirection)
        }
        //display table        
        return(
            <TableWrapper>
                <Table>
                    {columnWidths}
                    <TableHeader>
                        <TableHeaderRow>
                            {tableFields.map(field=><TableCell key={field.fieldId}>{
                                field.isSortable?
                                <React.Fragment>
                                    <span onClick={()=>this.handleSort(field.fieldId)}>{field.displayName}</span>
                                    {sortingItem===field.fieldId?<SortIcon src={sortingDirection==="asc"?IconSortAsc:IconSortDesc} alt=""/>:<SortIcon src={IconSortNone} alt=""/>}
                                </React.Fragment>:
                                <span>{field.displayName}</span>
                            }</TableCell>)}
                        </TableHeaderRow>
                    </TableHeader>
                    <TableBody>
                        {proItems.length > 0
                            ?   proItems.map((item,index) =>
                                    <TableBodyRow key={primaryKey ? item[primaryKey] : index} rowId={primaryKey ? item[primaryKey] : index} onDoubleClickHandler={onDoubleClickHandler} onClickHandler={onClickHandler}activeRow={activeRow}>
                                        {tableFields.map((field) =>{

                                                return <TableCell key={field.fieldId} desc={field.fieldId === 'description' ? true : false}>
                                                    {field.fieldId === 'id'
                                                        ?   (index + 1)
                                                            :   field.fieldId === 'actions'
                                                            ?   actionButton({locationId:item[primaryKey],parentId:item[primaryKey],name:item['name']})
                                                            :   field.fieldId==='point'
                                                            ?   point(item[primaryKey],item['name'])
                                                            :   field.fieldId==='contentTypes'
                                                            ?   contentTypes(item['text'].enabled, item['audio'].enabled, item['video'].enabled)
                                                        :   item[field.fieldId]}
                                                </TableCell>
                                            })
                                        }
                                    </TableBodyRow>
                                )
                            :   <tr>
                                    <td style={{textAlign:'center', color:'#2D7865'}} colSpan={tableFields.length}>No data to display</td>
                                </tr>
                        }                        
                    </TableBody>
                </Table>
                {dataCount>0
                ? <Pagination count={dataCount} itemsToDisplay={itemsToDisplay} page={page} rowsPerPage={rowsPerPage} onChangeRowsPerPage={this.handleChangeRowsPerPage} onChangePage={this.handleChangePage}/>
                : null     
            }
            </TableWrapper>
        )
    }
}

export default DataTable;