import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
${({margin="0"})=>`
    margin:${margin};
`}
    width:48px;
    height:48px;
    position:relative;
    border-radius:50%;
    background:#ffffff;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    cursor:pointer;
    &::after{
        content:"";
        position:absolute;
        left:-3px;
        top:-3px;
        width:52px;
        height:52px;
        border-radius:50%;
        border:1px solid #ffffff;
        display:block;
    }
`

const OuterLines = styled.div`
    height:5px;
    width:30px;
    background-color:#2d7865;
    ${({margin="0"})=>`
    margin:${margin};
    `}
`

const InnerLinesUp = styled.div`
    height:5px;
    width:30px;
    background-color:#2d7865;
    transform-origin:center;

    ${({active})=>{
        return active
        ?`transform: rotate(45deg);`
        :``
    }}

    ${({margin="0"})=>`
    margin:${margin};
    `}
`

const InnerLinesDown = styled.div`
    height:5px;
    width:30px;
    background-color:#2d7865;
    transform-origin:center;

    ${({active})=>{
        return active
        ?`transform: rotate(-45deg);`
        :``
    }}

    ${({margin="0"})=>`
    margin:${margin};
    `}
`


const Burger = ({margin, active=true, onClickHandler=()=>{alert("clicked")}})=>{
    return(
        <Wrapper margin={margin} onClick = {()=>{onClickHandler()}}>
            {active
            ?   <React.Fragment>
                    <InnerLinesUp active margin="0 0 -5px 0"/>
                    <InnerLinesDown active/>
                </React.Fragment>
            :   <React.Fragment>
                    <OuterLines margin="0 0 2px 0"/>
                    <InnerLinesUp margin="0 0 2px 0"/>
                    <InnerLinesDown margin="0 0 2px 0"/>
                    <OuterLines/>
                </React.Fragment>
                }    
        </Wrapper>
    )
}

export default Burger;