import React from 'react';
import styled from 'styled-components';
import { defaultTransition } from "../../../../resources/style/functions";

const BodyRow = styled.tr`
    height: 45px;
    font-size:16px;
    ${defaultTransition}
    &:nth-child(odd) {
        ${props => props.active ? `background-color:#2d7865;
        color:#ffffff;`
        : `background-color: #ffffff;
            color:#2d7865;`}
    }
    &:nth-child(even) {
        ${props => props.active ? `background-color:#2d7865;
        color:#ffffff;`
        : `background-color: #ebfced;
        color:#2d7865;`}
    }
    &:hover {
        ${props => props.active ? `background-color:#2d7865;
        color:#ffffff;`
        : `background-color: #d0feed;
        color:#2d7865;`}
    }
    & td {
        border-bottom: 1px solid #2D7865;
    }
`;

const TableBodyRow = ({children=null,  onDoubleClickHandler=()=>{}, onClickHandler=()=>{},rowId, activeRow})=>{
    
    return(
        <BodyRow onDoubleClick={() => onDoubleClickHandler(rowId)} onClick={()=>onClickHandler(rowId)} active={rowId===activeRow}>
            {children}
        </BodyRow>
    )
}

export default TableBodyRow;