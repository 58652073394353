import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
    ${({ width='24', margin='0', fill='#000' }) => `
        width: ${width};
        margin: ${margin};
        fill: ${fill};
    `}
`;

const AudioIcon = ({ width, margin, fill }) => {
    return (
        <Svg width={width} margin={margin} fill={fill} height="20" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 18H0V6H7V18ZM9 6V18L20 24V0L9 6Z" />
        </Svg>
    )
}

export default AudioIcon;
