import React from 'react';
import styled from 'styled-components';
import Input from "../Input/Input";

const FormWrapper = styled.form`
    width: 100%;
    ${({ margin = '0'}) => `
        margin: ${margin};
    `}
`;

const Form = ({ formFields, margin, onTextInput}) => (
    <FormWrapper margin={margin}>
        {formFields.map(field => (
            <Input key={field.id} margin='0 0 57px 0' type={field.inputType} labelText={field.label} value={field.value} readOnly={field.readOnly} fieldId={field.id} onTextInput={onTextInput} errorMessageText={(field.touched && !field.valid) ? 'This field is invalid!' : null} autoFocus={field.autofocus} />
        ))}
    </FormWrapper>
);

export default Form;