import React, { Component } from 'react';
import styled from 'styled-components';


import DataTable from "../../components/ui/DataTable/DataTable";
import { globalVariables } from '../../utility/common';
import { Input, Button, RowActions, Frame, UploadImage, Modal, FormManager, PoiManager, LoadingSpinner, MessageBox} from '../../components/ui';
import Photo360Engine from '../../components/common/360Engine';

import { NavContextConsumer } from '../../utility/Contexts/NavigationContext'

import IconSearch from "../../resources/img/icon-search.png"; 
import { LocationContext } from '../../utility/Contexts/LocationContext';
import Icon360 from "../../resources/img/icon-360.png";
import IconPortal from '../../resources/img/icon-portal.png';
import IconPoi from '../../resources/img/icon-poi.png';

import { TextIcon, AudioIcon, VideoIcon } from '../../components/ui/Icons'

const PageWrapper = styled.div`
    display: flex;
    height: calc(100vh - ${globalVariables.headerHeight});
    width: 100%;
    background-color: #ecf1eb;
    overflow-x: hidden;
`;

const SideContentWrapper = styled.div`
    position: relative;
    ${({ mainContentShown })=>`
        width: ${mainContentShown ? globalVariables.sideContentWidth : '100%'};    
    `}
    height: 100%;
    z-index: 500;
`;

const SideContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: ${props => props.mainContentShown ? 'column' : 'row'};
    align-items: flex-start;
    padding: 13px 8px 0;
    background-color: #ecf1eb;
    overflow-x: hidden;
`;

const CrudWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const MainContentWrapper = styled.div`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - ${globalVariables.sideContentWidth});
    background-color: #ffffff;
`;

const Show360Button = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: -105px;
    width: 160px;
    height: 60px;
    padding: 0 15px 0 0;
    font-size: 18px;
    color: #ffffff;
    transform: translate(0, -50%);
    background-color: #2d7865;
    border-radius: 40px 0 0 40px;
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    transition: all 0.2s ease-in-out;
    z-index: 9999;
    cursor: pointer;
    &:hover {
        transform: translate(-105px, -50%);
    }
`;

const Hide360Button = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: -60px;
    width: 160px;
    height: 60px;
    padding: 0 0 0 20px;
    font-size: 18px;
    color: #ffffff;
    transform: translate(0, -50%);
    background-color: #2d7865;
    border-radius: 0 40px 40px 0;
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    transition: all 0.2s ease-in-out;
    z-index: -1;
    cursor: pointer;
    &:hover {
        transform: translate(100px, -50%);
    }
`;

const HidenFileInput = styled.input`
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

const UploadButton = styled.label`
    position: absolute;
    left: 20px;
    bottom: 20px;
    min-width: 40px;
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #2d7865;
    background-color:#fff;
    border: 1px solid #387f65;
    border-radius: 37px;
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    transition: all 0.2s ease-in-out;
    outline: none;
    cursor: pointer;
    &:hover {
        color: #ffffff;
        background-color: #2D7865;
    }
`;

const Icon = styled.img`
    width: 30px;
    margin: 0 auto;
`;

const ContentTypes = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100px;
`;

const portalTableFieldsShort= [
    {
        fieldId:'id',
        displayName: 'ID',
        isDefaultSort:true,
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'name',
        displayName: 'Portal Name',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'point',
        displayName: 'Portal Point',                    
        isSortable:false,
        isFilterable:false
    },
];

const portalTableFieldsFull= [
    {
        fieldId:'id',
        displayName: 'ID',
        isDefaultSort:true,
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'name',
        displayName: 'Portal Name',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'point',
        displayName: 'Portal Point',                    
        isSortable:false,
        isFilterable:false
    },
    {
        fieldId:'actions',
        displayName: 'Actions',                    
        isSortable:false,
        isFilterable:false
    },
];

const poiTableFields = [
    {
        fieldId:'id',
        displayName: 'ID',
        isDefaultSort:true,
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'name',
        displayName: 'Poi Name',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'point',
        displayName: 'Poi Point',                    
        isSortable:false,
        isFilterable:false
    },
    {
        fieldId:'contentTypes',
        displayName: '',                    
        isSortable:false,
        isFilterable:false
    },
];

const portalFormFields=[
    {
        id: 'name',
        inputType: 'text',
        value:'',
        label: 'portal name',
        autofocus: true,
        validation:{
            required:true,
            minLength:2,
            maxLength:50
        }
    }    
];

// const poiFormFields=[
//     {
//         id: 'name',
//         inputType: 'text',
//         value:'',
//         label: 'poi name',
//         autofocus: true,
//         validation:{
//             required:true,
//             minLength:1,
//             maxLength:50
//         }
//     },
//     {
//         id: 'description',
//         inputType: 'textarea',
//         value:'',
//         label: 'description',
//         validation:{
//             required:true,
//             minLength:1,
//             maxLength:1024
//         }
//     },
// ];

const searchPortalHelper = (portals,search)=>{    
    if(!portals) return [];
    if(portals.length===0) return portals;
    if(search.length===0) return portals;
    const searchData = portals.filter(d=>d.name.toLowerCase().includes(search.toLowerCase()));
    return searchData
};

const searchPoiHelper = (pois,search)=>{    
    if(!pois) return [];
    if(pois.length===0) return pois;
    if(search.length===0) return pois;
    const searchData = pois.filter(d=>d.name.toLowerCase().includes(search.toLowerCase()));
    return searchData
};

class Image360Viewer extends Component {

    //communtication code goes here
    state = {
        activeForm: null,
        mainContentShown: true,
        selectedPortal: '',
        selectedPoi: '',
        portalSearch: '',
        poiSearch: '',
    };

    handleOpenForm = (id="portal")=>{
        switch (id) {
            default:
            case "portal":
                this.setState({
                    ...this.state,
                    activeForm : <Modal title="Add Portal" onCancel={()=>this.handleCloseForm("portal")} onFinish={this.handleFormFinish}></Modal>
                });
                break;
            case "poi":
                this.setState({
                    ...this.state,
                    activeForm : <Modal title="Add Poi" onCancel={()=>this.handleCloseForm("poi")} onFinish={this.handleFormFinish}></Modal>
                });
                break;
        }
    };

    handleOpenPortalForm = ()=>{
        this.setState({
            ...this.state,
            activeForm : <FormManager title="ADD PORTAL" onCancel={this.handleCloseForm} onFinish={this.handlePortalFormFinish} fields={JSON.parse(JSON.stringify(portalFormFields))}/>
        })                
    };

    handlePortalFormFinish = async(portal) => {
        const data = {};
        portal.forEach(ptl => {            
            data[ptl.id] = ptl.value;            
        });
        await this.context.insertPortal(data);
        this.handleCloseForm();
    };

    handleOpenEditPortalForm = ()=>{
        if(this.state.selectedPortal.length===0) return;
        const portalIndex = this.context.portals.findIndex(l=>l.id === this.state.selectedPortal);
        if(portalIndex<0) return;
        const portal = this.context.portals[portalIndex];
        const portalFields= JSON.parse(JSON.stringify(portalFormFields));
        
        portalFields.forEach(field => {
            if(portal.hasOwnProperty(field.id)){
                field.value = portal[field.id];
            }
        });
        this.setState({
            ...this.state,
            activeForm : <FormManager edit title="EDIT PORTAL" onCancel={this.handleCloseForm} onFinish={this.handleEditPortalFormFinish} fields={JSON.parse(JSON.stringify(portalFields))}/>
        })               
    };

    handleEditPortalFormFinish = async(portal) => {
        const data = {};
        portal.forEach(loc => {data[loc.id] = loc.value;});        
        data.portalId = this.state.selectedPortal;
        await this.context.updatePortal(data);
        this.handleCloseForm();
    };

    handleOpenPortalMessageBox = ()=>{
        if(this.state.selectedPortal.length===0) return;
        this.setState({
            ...this.state,
            activeForm:<MessageBox onCancel={this.handleCloseMessageBox} onConfirm={this.handleConfirmPortalMessageBox} title="Confirm Delete" confirmButtonText='Delete'>Are you sure that you wish to delete this portal?</MessageBox>
        })
    };

    handleConfirmPortalMessageBox = ()=>{
        this.context.deletePortal(this.state.selectedPortal);
        this.setState({
            ...this.state,
            activeForm:null,
            selectedPortal:""
        })
    };

    handleOpenPoiForm = ()=>{
        this.setState({
            ...this.state,
            // activeForm : <FormManager title="ADD POI" onCancel={this.handleCloseForm} onFinish={this.handlePoiFormFinish} fields={JSON.parse(JSON.stringify(poiFormFields))}/>
            activeForm : <PoiManager title="ADD POI" onCancel={this.handleCloseForm} onFinish={this.handlePoiFormFinish} />
        })                
    };

    handlePoiFormFinish = async(poiData) => {
        await this.context.insertPoi(poiData);
        this.handleCloseForm();
    };

    handleOpenEditPoiForm = ()=>{
        if(this.state.selectedPoi.length===0) return;
        const poiIndex = this.context.pois.findIndex(l=>l.id === this.state.selectedPoi);
        if(poiIndex<0) return;
        const poi = this.context.pois[poiIndex];
        this.setState({
            ...this.state,
            activeForm : <PoiManager title="EDIT POI" onCancel={this.handleCloseForm} onFinish={this.handleEditPoiFormFinish} poiData={poi}/>
        })               
    };

    handleEditPoiFormFinish = async(poi) => {

        const data = {...poi};
        data.poiId = this.state.selectedPoi;
        await this.context.updatePoi(data);
        this.handleCloseForm();
    };

    handleOpenPoiMessageBox = ()=>{
        if(this.state.selectedPoi.length===0) return;
        this.setState({
            ...this.state,
            activeForm:<MessageBox onCancel={this.handleCloseMessageBox} onConfirm={this.handleConfirmPoiMessageBox} title="Confirm Delete" confirmButtonText='Delete'>Are you sure that you wish to delete this poi?</MessageBox>
        })
    };

    handleConfirmPoiMessageBox = ()=>{
        this.context.deletePoi(this.state.selectedPoi);
        this.setState({
            ...this.state,
            activeForm:null,
            selectedPoi:""
        })
    };

    handleCloseForm = ()=>{
        this.setState({
            ...this.state,
            activeForm:null
        })
    };

    handleToggleImage = (toggle) => {
        this.setState({
            ...this.state,
            mainContentShown:toggle
        })
    };

    handleSearchPortal = (e) =>{        
        this.setState({
            ...this.state,
            portalSearch:e.currentTarget.value
        })
    };

    handleSearchPoi = (e) =>{        
        this.setState({
            ...this.state,
            poiSearch:e.currentTarget.value
        })
    };

    handlePortalTableRowSelected = (id)=>{
        this.setState({
            ...this.state,
            selectedPortal:id
        })
    };

    handlePoiTableRowSelected = (id)=>{
        this.setState({
            ...this.state,
            selectedPoi:id
        })
    };

    handleCloseMessageBox = ()=>{
        this.setState({
            ...this.state,
            activeForm:null
        })
    };

    handleDragStart = (ev,type,data)=>{
        ev.dataTransfer.setData("id",data.id);
        ev.dataTransfer.setData("name",data.name);
        ev.dataTransfer.setData("type",type);
    };

    render() {
        const {activeForm, mainContentShown, selectedPortal, selectedPoi, portalSearch, poiSearch } = this.state;
        const {getPortal, uploadImage, dataLoading, data, portals, pois, updatePortalPosition, updatePoiPosition, hasError, errorMessage, handleRemoveError} = this.context;
        const {parentId} = this.props;
        
        const portalTableColumnWidths =     <colgroup>
                                                <col style={{width: "69px"}} />
                                                <col style={{width: "100%"}} />
                                                <col style={{width: "100px"}} />
                                                {!mainContentShown ? <col style={{width: "200px"}} /> : null}
                                            </colgroup>
        ;

        const PoiTableColumnWidths =   <colgroup>
                                            <col style={{width: "69px"}} />
                                            <col style={{width: "100%"}} />
                                            <col style={{width: "80px"}} />
                                            <col style={{width: "100px"}} />
                                        </colgroup>
        ;
        let locationData = {};
        let showImage = false;
        let portalsToDisplay=[];
        let poisToDisplay=[];
        if(data){
            portalsToDisplay = searchPortalHelper(portals,portalSearch);
            poisToDisplay = searchPoiHelper(pois,poiSearch);
            locationData = {locationId:data.id,parentId};
            showImage = data.image?true:false;
        }
        return (
            <PageWrapper>
                <SideContentWrapper mainContentShown={mainContentShown}>
                    <SideContent mainContentShown={mainContentShown}>
                        <Frame title='Portal Settings' margin='0 40px 0 0'>
                            <Input placeholderText="Search by Portal Name" icon={IconSearch} margin="0 0 14px 0" value={portalSearch} onTextInput={this.handleSearchPortal}/>
                            <CrudWrapper>
                                <Button margin="0 20px 10px 20px" onClickHandler={() => this.handleOpenPortalForm()}>ADD PORTAL</Button>
                                <Button margin="0 20px 10px 0" disabled={selectedPortal.length === 0} onClickHandler={() => this.handleOpenEditPortalForm()}>EDIT</Button>
                                <Button margin="0 0 10px 0" disabled={selectedPortal.length === 0} onClickHandler={() => this.handleOpenPortalMessageBox()}>DELETE</Button>
                            </CrudWrapper>
        
                            <DataTable columnWidths={portalTableColumnWidths}
                                        rowsDraggable={true}
                                        maxRows={10}
                                        tableFields={mainContentShown ? portalTableFieldsShort : portalTableFieldsFull}
                                        data={portalsToDisplay}
                                        onDoubleClickHandler={()=>{}}
                                        onClickHandler={this.handlePortalTableRowSelected}
                                        activeRow={selectedPortal}
                                        primaryKey='id'
                                        point = {(itemId,name)=><Icon src={IconPortal} alt="" draggable onDragStart={(e)=>this.handleDragStart(e,'portal',{id:itemId,name})}/>}
                                        actionButton = {(e)=><RowActions onClick={()=>getPortal({id:e.locationId,name:e.name})}  actionButtonText={"ADD 360 PHOTO"}/>}
                                        />
                        </Frame>
                        
                        <Frame title='POI Settings'>
                            <Input placeholderText="Search by Poi Name" icon={IconSearch} margin="0 0 14px 0" value={poiSearch} onTextInput={this.handleSearchPoi}/>
                            <CrudWrapper>
                                <Button margin="0 20px 10px 20px" onClickHandler={() => this.handleOpenPoiForm()}>ADD POI</Button>
                                <Button margin="0 20px 10px 0" disabled={selectedPoi.length === 0} onClickHandler={() => this.handleOpenEditPoiForm()}>EDIT</Button>
                                <Button margin="0 0 10px 0" disabled={selectedPoi.length === 0} onClickHandler={() => this.handleOpenPoiMessageBox()}>DELETE</Button>
                            </CrudWrapper>
        
                            <DataTable columnWidths={PoiTableColumnWidths}                            
                                        maxRows={10}
                                        rowsDraggable={true}
                                        tableFields={poiTableFields}
                                        data={poisToDisplay}
                                        onDoubleClickHandler={()=>{}}
                                        onClickHandler={this.handlePoiTableRowSelected}
                                        activeRow={selectedPoi}
                                        primaryKey='id'
                                        point = {(itemId,name)=><Icon src={IconPoi} alt="" draggable onDragStart={(e)=>this.handleDragStart(e,'poi',{id:itemId,name})}primaryKey/>}
                                        contentTypes = {(textEnabled, audioEnabled, videoEnabled) =>    <ContentTypes>
                                                                                                            <TextIcon width='20px' fill={textEnabled ? '#8AC53D' : '#ADADAD'} />
                                                                                                            <AudioIcon width='20px' fill={audioEnabled ? '#8AC53D' : '#ADADAD'} />
                                                                                                            <VideoIcon width='20px' fill={videoEnabled ? '#8AC53D' : '#ADADAD'} />
                                                                                                        </ContentTypes>}
                                        />
                        </Frame>

                    </SideContent>
                        {mainContentShown
                            ?   <Hide360Button onClick={()=>this.handleToggleImage(false)}>
                                    Hide 360
                                    <Icon src={Icon360} alt="" margin='0 0 0 30px' />
                                </Hide360Button>
                            :   <Show360Button onClick={()=>this.handleToggleImage(true)}>
                                    <Icon src={Icon360} alt="" />
                                    Show 360
                                </Show360Button>
                        }
                </SideContentWrapper>
                {mainContentShown
                    ?   dataLoading
                        ?   <LoadingSpinner />
                        :   <MainContentWrapper>
                                {showImage
                                    ?   <>
                                            <Photo360Engine image={data.image} portals={portalsToDisplay} pois={poisToDisplay} onPortalClicked={getPortal} onPortalDropped={updatePortalPosition} onPoiDropped={updatePoiPosition}/>
                                            <HidenFileInput id='file-input' type='file' onChange={e => uploadImage(e.target.files)} />
                                            <UploadButton htmlFor='file-input'>Change image</UploadButton>
                                        </>
                                    :   <UploadImage handleImageUpload={e=>uploadImage(e,locationData)}/>} 
                                <NavContextConsumer>
                                        {({ onItemChange }) => <Button customStyle={'position: absolute; bottom: 20px; right: 20px; height: 40px; padding: 0 20px; border-radius: 37px; font-size: 20px;'} onClickHandler={() => onItemChange({id: 'locationViewer', pageName: 'Locations View'})}>FINISH</Button>}
                                </NavContextConsumer>
                            </MainContentWrapper>
                    :   null
                }
                {activeForm}
                {hasError ? <MessageBox title="Error" confirmButtonText='Close' onConfirm={handleRemoveError}>{errorMessage}</MessageBox> : null}
            </PageWrapper>
        );
    }
}
Image360Viewer.contextType = LocationContext;
export default Image360Viewer;