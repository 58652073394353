import React from "react";
import styled from "styled-components";
import TickIcon from '../../../resources/img/icon-tick.png';
import IconX from '../../../resources/img/icon-x.png';
const SwitchWrapper = styled.label`
    ${({ margin='0' }) => `
        margin: ${margin};
    `}
    display: flex;
    flex-wrap: nowrap;
    font-size: 20px;
    letter-spacing: -1px;
    color: #2d7865;
    vertical-align: middle;
`

const SwitchSlider = styled.div`
    position: relative;
    display: inline-block;
    width: 48px;
    height: 28px;
    background-color: #ffffff;
    border-radius: 20px;
    transition: .4s;
    cursor: pointer;    
`

const CheckboxAsSwitch = styled.input`
    display: none;
`;
    
const SwitchLabel = styled.span`
    margin-right: 10px;
    line-height: 28px;
`;

const SwitchIconContainer = styled.div`
    ${({checked})=>{
        return checked
        ? ` transform: translateX(20px);
            background-color: #2D7865;`
        : ` background-color: #808080;
        `
    }}    
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    bottom: 2px;
    left: 2px;
    color: #ffffff;
    border-radius: 50%;
    transition: all 0.25s ease-in-out;
`;

const Switch = ({ margin, labelText, checked = false, onSwitch = () => console.log('Not implemented') }) => (
    <SwitchWrapper htmlFor="projects-switch-input-1" margin={margin}>
        {labelText ? <SwitchLabel>{labelText}</SwitchLabel> : null}
        <CheckboxAsSwitch type="checkbox" readOnly checked={checked} id='projects-switch-input-1' />
        <SwitchSlider onClick={() => onSwitch()} >
            <SwitchIconContainer checked={checked}>
                {checked
                ? <img src={TickIcon} alt=""/>
                : <img src={IconX} alt=""/>}
            </SwitchIconContainer>
        </SwitchSlider>
    </SwitchWrapper>
);

export default Switch;
