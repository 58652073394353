import React, { Component } from 'react';

const NavContext = React.createContext();

class NavContextProvider extends Component {
    handleChangeActiveItem = page => {
        this.setState({
            ...this.state,
            activeItem: page.id,
            pageName: page.pageName,
            parentId: page.parentId||""
        })
    }
    handleLogout = ()=>{
        this.setState({
            ...this.state,
            activeItem: this.props.defaultView.activeItem || null,
            pageName: this.props.defaultView.pageName
        })
    }
    state = { 
        activeItem: this.props.defaultView.activeItem || null,
        pageName: this.props.defaultView.pageName,
        onItemChange: this.handleChangeActiveItem,
        handleLogout: this.handleLogout,
        parentId:""
    }
    
    render() {
        return (
            <NavContext.Provider value={this.state}>
                { this.props.children }
            </NavContext.Provider>
        );
    }
}

const NavContextConsumer = NavContext.Consumer;

export { NavContextProvider, NavContext, NavContextConsumer};