import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
    position: relative;
    ${({margin="0"})=>`
        margin: ${margin};
    `}
`;

const StyledLabel = styled.label`
    position: absolute;
    left: 1px;
    bottom: calc(100% - 7px);
    padding: 0 9px;
    font-size: 24px;
    letter-spacing: -1.2px;
    color: #206857;
    background: #ffffff;
`;

const StyledInput = styled.input`
    width: 601px;
    height: 70px;
    padding: 0 18px;
    border: 1px solid #206857;
    ${ ({borderRadiusPosition='top'}) => `
        border-${borderRadiusPosition}-right-radius: 60px;
    `}
    font-size: 18px;
    letter-spacing: -0.9px;
    color: rgba(32, 104, 87, 1);
    &::placeholder {
        font-size: 18px;
        letter-spacing: -0.9px;
        color: rgba(32, 104, 87, 0.5);
    }
`;

const ErrorMessage = styled.p`
    position: absolute;
    top: calc(100% + 19px);
    font-size: 18px;
    color: #f24f54;
`;

const LoginInput = ({ margin, labelText, placeholderText, errorMessageText, borderRadiusPosition, value, type="text", onTextInput=e=>{console.log(e.currentTarget.value)} }) => (
    <InputWrapper margin={margin}>
        <StyledLabel>{labelText}</StyledLabel>
        <StyledInput type={type} placeholder={placeholderText} borderRadiusPosition={borderRadiusPosition} onChange={e=>onTextInput(e.currentTarget.value)} value={value}/>
        {errorMessageText?<ErrorMessage>{errorMessageText}</ErrorMessage>:null}
    </InputWrapper>
);

export default LoginInput;
