import React from 'react';
import styled from 'styled-components';
import {LoginButton, ClickableLabel} from '../';


const BackgroundOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 5%;
    background: hsla(0, 0%, 0%, 0.6);
    box-sizing: border-box;
    z-index: 9999;
`;

const ModalContainer = styled.div`
    position: relative;
    max-height: calc(100vh - 10%);
    width: 640px;
    padding: 20px;
    background-color: rgba(45, 120, 101, 1);
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    overflow-x: auto;
`;

const ModalTitle = styled.h1`
    margin-bottom: 20px;
    font-size: 36px;
    text-align: center;
    line-height: normal;
    color: #ffffff;
`;

const ModalContent = styled.div`
    font-size: 18px;
    text-align: center;
    color: #ffffff;
`;

const ButtonsWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: 30px;
`

const MessageBox = ({ children, title, onCancel, onConfirm, confirmButtonText='Confirm' }) => {
    return (
        <BackgroundOverlay>
            <ModalContainer>
                {title ? <ModalTitle>{title}</ModalTitle> : null}

                <ModalContent>
                    { children || 'Nema podataka za prikaz' }
                    <ButtonsWrapper>
                        <LoginButton minWidth="105px" height="36px" fontSize="16px" onClickHandler={()=>onConfirm()}>{confirmButtonText}</LoginButton>
                        {onCancel ? <ClickableLabel fontSize="16px" color="#ffffff" margin="0 0 0 20px" onClickHandler={()=>onCancel()}>Cancel</ClickableLabel> : null}
                    </ButtonsWrapper>
                </ModalContent>
            </ModalContainer>
        </BackgroundOverlay>
    );
}

export default MessageBox;