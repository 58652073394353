import React from 'react';
import styled from 'styled-components';

import { Button, PopupButton } from '../';

import MoreInfo from '../../../resources/img/icon-more-info.png';
import LessInfo from '../../../resources/img/icon-less-info.png';
import IconDelete from '../../../resources/img/icon-delete.png';


const CardWrapper = styled.div`
    position:relative;
    width:356px;
    color:#2d7865;
    text-align:left;
    background-color: #2d7865;
`

const ContentWrapper = styled.div`
    position: relative;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: #ffffff;
    border-radius: 5px 40px 5px 5px;
`

const Heading = styled.h3`
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
`

const GeolocWrapper = styled.div`
    margin: 0;
`

const GeolocData = styled.p`
    margin: 0 !important;
`

const DescriptionWrapper = styled.p`
    max-height: 200px;
    overflow-y: auto;
    outline: none;
`

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DetailsCard = ({ cardTitle,longitude,latitude,descriptionText, expanded=true, onAdd360 = () => {alert('Add 360')}})=>{
    return(
        <CardWrapper>
            <ContentWrapper>
                <Heading>{cardTitle}</Heading>
                {longitude&&latitude
                ?   <GeolocWrapper>
                        <GeolocData>Latitude: {latitude}</GeolocData>
                        <GeolocData>Longitude: {longitude}</GeolocData>
                    </GeolocWrapper>
                : null}
                <DescriptionWrapper>{descriptionText}</DescriptionWrapper>
            </ContentWrapper>
            <ButtonsWrapper>
                <Button onClickHandler={onAdd360}>ADD 360˚ PHOTO</Button>                
            </ButtonsWrapper>
        </CardWrapper>
    )
}

export default DetailsCard;