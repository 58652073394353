import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    ${({margin="0"})=>`
    margin:${margin};
    `}
    min-width: 40px;
    height: 36px;
    padding: 0 15px;
    font-size: 16px;
    text-transform:uppercase;
    letter-spacing: -0.8px;
    color: #2d7865;
    background-color:#fff;
    border-radius: 40px;
    border: solid 1px #387f65;
    outline: none;
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    vertical-align: middle;
    &:hover {
        color: #ffffff;
        background-color: #2D7865;
    }
    &[disabled] {
        color: #a9a9a9;
        background-color: #dedede;
        border-color: transparent;
        cursor: not-allowed;
        box-shadow: none;
    }
    ${({ customStyle = '' }) => `
        ${customStyle}
    `}
`

const Button = ({ margin, children="Button", disabled = false, customStyle, onClickHandler=()=>{alert("Clicked")}}) => <StyledButton margin={margin} disabled={disabled} customStyle={customStyle} onClick={e =>{e.stopPropagation(); onClickHandler()}}>{children}</StyledButton>;

export default Button;