import React from 'react';
import styled from 'styled-components';

const Header = styled.thead``;

const TableHeader = ({ children=null })=>{
    return(
        <Header>
            {children}
        </Header>
    );
};

export default TableHeader;