import React from 'react';
import styled from 'styled-components';

import ClickableLabel from '../ClickableLabel/ClickableLabel';

const BreadWrapper = styled.div`
    padding:0 40px;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-wrap:nowrap;
    overflow:auto;
    color:#2D7865;
    font-size:20px;
`

const BreadCrumbs = ({pageName, onClick, locations=[]})=>{
    return(
        <BreadWrapper>
            {<p><strong>{pageName}</strong></p>}

            {pageName === '360º Photo View'
                ? locations.map(location=><ClickableLabel key={location.parentId} onClickHandler={()=>onClick({locationId:location.locationId,parentId:location.parentId,name:location.name})} fontSize='18px' fontWeight='400' margin='0 0 0 5px'>{`/ ${location.name}`}</ClickableLabel>)
                : null
            }
        </BreadWrapper>
    )
}

export default BreadCrumbs;