import React, {Component} from "react";
import styled from "styled-components";
import LoginBG from '../../resources/img/login-bg.jpg';
import ResetBG from '../../resources/img/reset-bg.jpg';
import IconBack from '../../resources/img/icon-back.png';
import {LoginButton, LoginInput, ClickableLabel, MessageBox, LoadingSpinner} from '../../components/ui';
import {AuthContextConsumer, AuthContext} from '../../utility/Contexts/AuthContext';
import { checkValidity } from "../../utility/utility";

const PageWrapper = styled.div`
    display:flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`;

const ImageWrapper = styled.div`
    display:inline-block;
    height: 100vh;
`;

const Image = styled.img`
    height: 100%;
`

const FormOuterWrapper = styled.div`
    position: relatve;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex:1;
`

const FormInnerWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
`

const FormHeading = styled.h2`
    color: #59C9E5;
    font-size:36px;    
    font-weight:300;
    margin-bottom: 128px;
`

const FormText = styled.p`
    color: #2D7865;
    font-size: 24px;
    width:543px;
    margin-bottom: 136px;
    
`

const BackIcon = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    border: 1px solid #2D7865;
    border-radius: 50%;
    cursor: pointer;
`

class LoginPage extends Component {
    state={
        showResetPassword:false,
        email:"",
        password:"",
        resetEmail:"",
        emailFieldValid: false,
        passwordFieldValid: false,
    }

    componentDidMount() {
        document.addEventListener('keyup', this.handleEnterListener);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleEnterListener);
    }

    handleEnterListener = (e) => {
        let key = e.which || e.keyCode;
        if (key === 13) {
            if (this.state.showResetPassword) {
                this.handleConfirmForgotPassword();
            } else {
                if (!this.state.emailFieldValid || !this.state.passwordFieldValid) return;

                let email = this.state.email;
                let password = this.state.password;
                this.context.login({email, password});
            }
        }
    }

    handleForgotPassword=()=>{
        this.setState({
            ...this.state,
            showResetPassword:!this.state.showResetPassword,
            email:"",
            password:"",
        })
    }

    handleConfirmForgotPassword=async()=>{
        if (!this.state.resetEmailValid) return;
        await this.context.forgotPassword(this.state.resetEmail);
        
        this.setState({
            ...this.state,
            showResetPassword:!this.state.showResetPassword,
            resetEmail:"",
            email:"",
            password:"",
        })
    }
    handleEmailInput=(input)=>{
        const emailFieldValid = checkValidity(input, {isEmail: true});

        this.setState({
            ...this.state,
            email:input,
            emailFieldValid
        })
    }
    handlePasswordInput=(input)=>{
        const passwordFieldValid = input.length < 6 ? false : true;

        this.setState({
            ...this.state,
            password:input,
            passwordFieldValid
        })
    }
    handleResetEmailInput=(input)=>{
        const resetEmailValid = checkValidity(input, {isEmail: true});

        this.setState({
            ...this.state,
            resetEmail:input,
            resetEmailValid
        })
    }

    handleReturnToLogin = () => {
        this.setState({
            ...this.state,
            showResetPassword: false
        })
    }

    render(){
        const {showResetPassword, email, password, resetEmail, emailFieldValid, passwordFieldValid, resetEmailValid, messageBoxOpen} = this.state;
        const {login, dataLoading, hasError, errorMessage, handleRemoveError} = this.context;

        return (
            <PageWrapper>
                {!showResetPassword
                    ?   <React.Fragment>
                            <ImageWrapper>
                                <Image src={LoginBG} alt=""/>
                            </ImageWrapper>
                            <FormOuterWrapper>
                                <FormInnerWrapper>
                                    <FormHeading>Please Login to Continue</FormHeading>
                                    <LoginInput margin="0 0 74px 0" labelText="email" placeholderText="enter your email address" value={email} onTextInput={this.handleEmailInput}/>
                                    <LoginInput type="password" margin="0 0 74px 0" borderRadiusPosition="bottom" labelText="password" placeholderText="enter your passsword" value={password} onTextInput={this.handlePasswordInput}/>
                                    <ClickableLabel margin="0 0 74px 0" color="#2D7865" fontSize="24px" underline onClickHandler={this.handleForgotPassword}>Forgot password?</ClickableLabel>
                                    <LoginButton minWidth="196px" disabled={!emailFieldValid || !passwordFieldValid} onClickHandler={()=>login({email,password})}>Login</LoginButton>
                                    {dataLoading ? <LoadingSpinner /> : null}
                                </FormInnerWrapper>
                            </FormOuterWrapper>
                        </React.Fragment>
                    :   <React.Fragment>
                            <ImageWrapper>
                                <Image src={ResetBG} alt=""/>
                            </ImageWrapper>
                            <FormOuterWrapper>
                                <FormInnerWrapper>
                                    <FormHeading>Forgot your password?</FormHeading>
                                    <FormText>Enter the email address associated with your account. We will send you further instructions.</FormText>
                                    <LoginInput margin="0 0 74px 0" labelText="email" errorMessageText={resetEmailValid === false ? 'Please enter a valid email!' : null} placeholderText="enter your email address" value={resetEmail} onTextInput={this.handleResetEmailInput}/>
                                    <LoginButton minWidth="196px" disabled={!resetEmailValid} onClickHandler={this.handleConfirmForgotPassword}>Confirm</LoginButton>
                                </FormInnerWrapper>
                                <BackIcon onClick={() => this.handleReturnToLogin()}>
                                    <img src={IconBack} alt=""/>
                                </BackIcon>
                            </FormOuterWrapper>
                        </React.Fragment>}
                {hasError ? <MessageBox title="Error" confirmButtonText='Close' onConfirm={handleRemoveError}>{errorMessage}</MessageBox> : null}
                {dataLoading ? <LoadingSpinner /> : null}
            </PageWrapper>
        );

        // if(!showResetPassword)
        //     return(
        //         <PageWrapper>
        //             <ImageWrapper>
        //                 <Image src={LoginBG} alt=""/>
        //             </ImageWrapper>
        //             <FormOuterWrapper>
        //                 <FormInnerWrapper>
        //                     <FormHeading>Please Login to Continue</FormHeading>
        //                     <LoginInput margin="0 0 74px 0" labelText="email" placeholderText="enter your email address" value={email} onTextInput={this.handleEmailInput}/>
        //                     <LoginInput errorMessageText={hasError ? errorMessage : null} type="password" margin="0 0 74px 0" borderRadiusPosition="bottom" labelText="password" placeholderText="enter your passsword" value={password} onTextInput={this.handlePasswordInput}/>
        //                     <ClickableLabel margin="0 0 74px 0" color="#2D7865" fontSize="24px" underline onClickHandler={this.handleForgotPassword}>Forgot password?</ClickableLabel>
        //                     <LoginButton minWidth="196px" disabled={!emailFieldValid || !passwordFieldValid} onClickHandler={()=>login({email,password})}>Login</LoginButton>
        //                     {dataLoading ? <LoadingSpinner /> : null}
        //                 </FormInnerWrapper>
        //             </FormOuterWrapper>
        //         </PageWrapper>
        //     )
        // else
        //     return(
        //         <PageWrapper>
        //             <ImageWrapper>
        //                 <Image src={ResetBG} alt=""/>
        //             </ImageWrapper>
        //             <FormOuterWrapper>
        //                 <FormInnerWrapper>
        //                     <FormHeading>Forgot your password?</FormHeading>
        //                     <FormText>Enter the email address associated with your account. We will send you further instructions.</FormText>
        //                     <LoginInput margin="0 0 74px 0" labelText="email" errorMessageText={resetEmailValid === false ? 'Please enter a valid email!' : null} placeholderText="enter your email address" value={resetEmail} onTextInput={this.handleResetEmailInput}/>
        //                     <LoginButton minWidth="196px" disabled={!resetEmailValid} onClickHandler={this.handleConfirmForgotPassword}>Confirm</LoginButton>
        //                 </FormInnerWrapper>
        //                 <BackIcon onClick={() => this.handleReturnToLogin()}>
        //                     <img src={IconBack} alt=""/>
        //                 </BackIcon>
        //             </FormOuterWrapper>
        //         </PageWrapper>
        //     )
    }
}
LoginPage.contextType = AuthContext;
export default LoginPage;