import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { AuthContextProvider } from './utility/Contexts/AuthContext';
import { NavContextProvider } from './utility/Contexts/NavigationContext';
// import * as serviceWorker from './serviceWorker';
import Router from './router';

// import Test from './test';

const GlobalStyle = createGlobalStyle`
  * {
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  button, input {
    outline: none;
  }
`

const defaultView = {
    activeItem: 'locationViewer',
    pageName: 'Locations View',
}

ReactDOM.render(<React.Fragment>
    <GlobalStyle/>
      <NavContextProvider defaultView={defaultView}>
        <AuthContextProvider>
          <Router/>
        </AuthContextProvider>
      </NavContextProvider>
</React.Fragment>, document.getElementById('root'));
// ReactDOM.render(<React.Fragment>
//     <GlobalStyle/>
//     <Test/>
// </React.Fragment>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
