import React from 'react';
import {AuthContextConsumer} from './utility/Contexts/AuthContext';
import { NavContextConsumer } from './utility/Contexts/NavigationContext';
import { LocationContextProvider } from './utility/Contexts/LocationContext';

import Login from './pages/Login/Login';
import Header from './components/common/Header';
import LocationViewer from './pages/LocationViewer/LocationViewer';
import Image360Viewer from './pages/360Viewer/360Viewer';
import AccountSettings from './pages/AccountSettings/AccountSettings';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import AboutInstitute from './pages/AboutInstitute/AboutInstitute';
import AboutProject from './pages/AboutProject/AboutProject';
import {AdminContextProvider} from './utility/Contexts/AdminContext';
const pages = {
    locationViewer: e=><LocationViewer />,
    image360Viewer: e=><Image360Viewer parentId={e}/>,
    accountSettings: e=><AccountSettings />,
    adminPanel: e=><AdminContextProvider>
                        <AdminPanel/>
                    </AdminContextProvider>,
    aboutInstitute: e => <AboutInstitute />,
    aboutProject: e => <AboutProject />,
}

const Router = ()=>{
    return(
        <LocationContextProvider>
            <AuthContextConsumer>
                {({loggedin})=>{
                    return loggedin
                        ?   <React.Fragment>
                                <Header/>
                                <NavContextConsumer>
                                    {({ activeItem, parentId }) => {
                                        return pages.hasOwnProperty(activeItem)
                                                ? pages[activeItem](parentId||null)
                                                : <p>404 Page not found</p>
                                        }
                                    }
                                </NavContextConsumer>
                            </React.Fragment>
                        :   <Login/>
                }}
            </AuthContextConsumer>
        </LocationContextProvider>
    )
}

export default Router;