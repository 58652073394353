import { darkTheme, lightTheme } from './colors';

export const boxShadow = (x = '0', y = '5px', blur = '15px') => `box-shadow: ${x} ${y} ${blur} rgba(0, 0, 0, 0.25);`;

export const defaultAnimation = (property = 'all', duration='0.1s', easing='ease-out') => `
    transition : ${property} ${duration} ${easing};
`

export const defaultTransition = `transition: all 0.1s ease-out;`;

export const ThemeChanger = (theme)=>{
    let themeColorObj = theme==='dark'
        ? darkTheme
        : lightTheme

    return themeColorObj;
}