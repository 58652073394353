import React from 'react';
import styled from 'styled-components';

import {AuthContextConsumer} from '../../../utility/Contexts/AuthContext';

import IconSettings from '../../../resources/img/icon-gear.png';
import IconAboutProject from '../../../resources/img/icon-about-project.png';
import IconAboutInstitute from '../../../resources/img/icon-about-institute.png';
import IconAdminPanel from '../../../resources/img/icon-admin-panel.png';
import IconLogOut from '../../../resources/img/icon-log-out.png';

const UserMenuWrapper = styled.ul`
    position: absolute;
    top: 110px;
    right: 40px;
    display: block;
    padding: 1px;
    background-color: #2d7865;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    z-index: 9999;
`;

const UserMenuItem = styled.li`
    display: flex;
    flex-wrap: nowrap;
    width: 202px;
    height: 80px;
    color: #ffffff;
    list-style: none;
    z-index: 1;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;

const IconWrapper = styled.div`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 72px;
    height: 80px;
    &::after {
        position: absolute;
        content: "";
        display: block;
        width: 1px;
        top: 25px;
        right: 0;
        bottom: 25px;
        background-color: #ffffff;
    }
`;

const TextWrapper = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    width: 100%;
    font-size: 20px;
    text-align: center;
    word-spacing: ${( props ) => props.icon ? '60px' : 'normal'};
`;

const AppVersionContainer = styled.div`
    font-size: 12px;
    color: #ffffff;
    text-align: center;
`;

const userMenuItems=[
    {
        id: 'accountSettings',
        pageName: 'Account Settings',
        icon: IconSettings,
    },
    {
        id: 'aboutProject',
        pageName: 'About Project',
        icon: IconAboutProject,
    },
    {
        id: 'aboutInstitute',
        pageName: 'About Institute',
        icon: IconAboutInstitute,
    },
    {
        id: 'logOut',
        pageName: 'Log Out',
        icon: IconLogOut,
    },
];

const adminMenuItems=[
    {
        id: 'accountSettings',
        pageName: 'Account Settings',
        icon: IconSettings,
    },
    {
        id: 'aboutProject',
        pageName: 'About Project',
        icon: IconAboutProject,
    },
    {
        id: 'aboutInstitute',
        pageName: 'About Institute',
        icon: IconAboutInstitute,
    },
    {
        id: 'adminPanel',
        pageName: 'Admin Panel',
        icon: IconAdminPanel,
    },
    {
        id: 'logOut',
        pageName: 'Log Out',
        icon: IconLogOut,
    },
];

const UserMenu = ({ appVersion = '', onChangeActiveItem = () => {alert('Menu item clicked!')} }) => (
    <UserMenuWrapper>
        <AuthContextConsumer>
            {({logout, isAdmin})=> {
                const menuItems = isAdmin ? adminMenuItems : userMenuItems;
                return menuItems.map(item => (
                    <UserMenuItem key={item.id} onClick={() => {item.id==="logOut"? logout() : onChangeActiveItem(item)}} >
                        {item.icon
                            ?   <IconWrapper>
                                    <img src={item.icon} alt="" />
                                </IconWrapper>
                            :   null}
        
                        <TextWrapper icon={item.icon} >{item.pageName}</TextWrapper>
                    </UserMenuItem>))}
            }
        </AuthContextConsumer>
        {appVersion ? <AppVersionContainer>{appVersion}</AppVersionContainer> : null}
    </UserMenuWrapper>
);

export default UserMenu;
