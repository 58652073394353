import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
    position: relative;
    width:100%;
    ${({margin="0"})=>`
        &:not(:last-child) {
            margin: ${margin};    
        }
    `}
`;

const StyledLabel = styled.label`
    position: absolute;
    left: 15px;
    bottom: calc(100% + 5px);
    font-size: 16px;
    color: #ffffff;
    &[readOnly] {
        color: #2D7865;
        cursor: not-allowed;
    }
`;

const StyledInput = styled.input`
    width: 100%;
    height: 37px;
    ${({padding="0 20px"})=>`
        padding:${padding};    
    `}
    border: none;
    border-radius:20px;
    font-size: 16px;
    color: #2d7865;
    transition: all 0.25s ease-in-out;
    &::placeholder {
        font-size: 16px;
        letter-spacing: -0.8px;
        color: rgba(32, 104, 87, 0.5);
        transition: all 0.25s ease-in-out;
    }
    &[readOnly] {
        font-size: 14px;
        color: #9F9F9F;
        background-color: #ECF1EB;
        cursor: not-allowed;
    }
    &[disabled] {
        color: #9F9F9F;
        background-color: #ECF1EB;
        cursor: not-allowed;
        &::placeholder {
            color: #a9a9a9;
        }
    }
`;

const StyledTextarea = styled.textarea`
    width: 100%;
    ${({padding="10px 20px"})=>`
        padding:${padding};    
    `}
    border: none;
    border-radius:20px;
    font-size: 16px;
    letter-spacing: -0.8px;
    color: #2d7865;
    resize:none;
    outline:none;
    transition: all 0.25s ease-in-out;
    &::placeholder {
        font-size: 16px;
        letter-spacing: -0.8px;
        color: rgba(32, 104, 87, 0.5);
        transition: all 0.25s ease-in-out;
    }
    &[disabled] {
        color: #a9a9a9;
        background-color: #dedede;
        border-color: transparent;
        cursor: not-allowed;
        box-shadow: none;
        &::placeholder {
            color: #a9a9a9;
        }
    }
`;

const Icon = styled.img`
    position: absolute;
    left: 20px;
    top: 50%;
    transform:translateY(-50%);
`;

const ErrorMessage = styled.p`
    position: absolute;
    top: 100%;
    left: 15px;
    font-size: 16px;
    color: #f24f54;
`;

const Input = ({ margin,fieldId, type, value='', labelText, placeholderText, autoFocus=false, icon, errorMessageText, onTextInput, readOnly=false, disabled=false }) => {
    let input = null;

    switch (type) {
        default:
        case 'text':
            input = <StyledInput type='text' autoFocus={autoFocus} padding={icon?"0 20px 0 62px":"0 20px"} value={value} readOnly={readOnly} disabled={disabled} placeholder={placeholderText} onChange={e=>onTextInput(e,fieldId)}/>
            break;
        case 'textarea':
            input = <StyledTextarea value={value} autoFocus={autoFocus} readOnly={readOnly} disabled={disabled} placeholder={placeholderText} rows={6} onChange={e=>onTextInput(e,fieldId)} />
            break;
        case 'number':
            input = <StyledInput type='number' autoFocus={autoFocus} padding={icon?"0 20px 0 62px":"0 20px"} value={value} readOnly={readOnly} disabled={disabled} placeholder={placeholderText} onChange={e=>onTextInput(e,fieldId)}/>
            break;
        case 'password':
            input = <StyledInput type='password' autoFocus={autoFocus} padding={icon?"0 20px 0 62px":"0 20px"} value={value} readOnly={readOnly} disabled={disabled} placeholder={placeholderText} onChange={e=>onTextInput(e,fieldId)}/>
            break;
    }

    return (
        <InputWrapper margin={margin}>
            {labelText
                ?   <StyledLabel readOnly={readOnly}>{labelText}</StyledLabel>
                :   null}

            {icon ? <Icon src={icon}/> : null}

            {input}

            {errorMessageText ? <ErrorMessage>{errorMessageText}</ErrorMessage> : null}
        </InputWrapper>
    );
};

export default Input;
