import React from "react";
import styled from "styled-components";

const StyledClickableLabel = styled.div`
    ${({ fontSize="30px", fontWeight="600", color="#1a6552", margin="0" })=>`
    font-size: ${fontSize};
    color: ${color};
    margin: ${margin};
    font-weight: ${fontWeight};
    `}
    display:inline-block;   
    text-decoration: ${ props => props.underline ? 'underline' : 'none'};
    cursor: pointer;
    white-space: nowrap;
`;

const ClickableLabel = ({underline, margin, fontSize, fontWeight, color, children="Clickable Label", onClickHandler=()=>{alert("Clicked")}}) => <StyledClickableLabel underline={underline} margin={margin} fontSize={fontSize} fontWeight={fontWeight} color={color} onClick={() => onClickHandler()}>{children}</StyledClickableLabel>;

export default ClickableLabel;
