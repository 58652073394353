import React, { Component } from 'react';
import {Form,Modal} from '../'
import { checkValidity } from '../../../utility/utility';


class FormManager extends Component{
    state={        
        formIsValid:false,
        formFields:[]
    }
    componentWillMount(){
        const fields = JSON.parse(JSON.stringify(this.props.fields));
        
        fields.forEach(field => {
            if (this.props.edit) {
                field.valid = true;
            } else {
                if (field.validation.required) {
                    field.valid = false;
                } else {
                    field.valid = true;
                }
            }
            
            field.touched = false;

        });
        this.setState({
            ...this.state,
            formFields:fields
        })
    }

    componentWillUnmount(){        
        this.setState({
            formFields:null,
            formIsValid:false
        })
    }

    inputChangedHandler=(event,inputIdentifier) =>{
        const newFormFields = [...this.state.formFields];
        const prevFormFields = JSON.parse(JSON.stringify(this.props.fields));
        const fieldIndex = newFormFields.findIndex(field=>field.id === inputIdentifier);
        const prevFieldIndex = prevFormFields.findIndex(field=>field.id === inputIdentifier);
        const prevField = prevFormFields[prevFieldIndex];
        const field = newFormFields[fieldIndex];
        field.value = event.target.value;
        field.touched = prevField.value!==event.target.value;
        field.valid = checkValidity(event.target.value, field.validation);
        newFormFields[fieldIndex] = field;
        let formIsValid;
        if(!this.props.edit)
        {
            formIsValid = true;
            newFormFields.forEach(formField => {
                formIsValid = (formField.valid && (!formField.validation.required || formField.touched)) && formIsValid;
            });
            
        }else{
            formIsValid = true;
            let formIsTouched = false;
            newFormFields.forEach(formField => {
                if (!formField.valid) formIsValid = false;
                if (formField.touched) formIsTouched = true;
            });
            formIsValid = formIsTouched && formIsValid;
        }
        this.setState({
            formFields: newFormFields,
            formIsValid
        })     
    };

    render(){
        const {title, confirmButtonText, onCancel, onFinish} = this.props;
        const {formFields,formIsValid} = this.state;
        
        return(
            <Modal title={title} confirmButtonText={confirmButtonText} onCancel={onCancel} onFinish={formIsValid? ()=>onFinish(this.state.formFields): ()=>{}} disabled={!formIsValid} >
                <Form formFields={formFields} onTextInput={this.inputChangedHandler}/>
            </Modal>
        )   
    }
}

export default FormManager;