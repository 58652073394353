import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
    table-layout: fixed;
    width: 100%;
    font-size: 14px;
    color: #ffffff;
    border-collapse: separate;
    border-spacing: 0px;
    cursor: default;
`;

const Table = ({children=null})=>{
    return(
        <StyledTable>
            {children}
        </StyledTable>
    )
}

export default Table;