import React, { Component } from 'react';
import {Map, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import icon from '../../resources/img/marker-icon.png';
import {DetailsCard} from '../ui';

const markerIconLarge = L.icon({
  iconUrl:icon,
  iconSize:[50,67],
  iconAnchor:[25,67]
})
const markerIconSmall = L.icon({
  iconUrl:icon,
  iconSize:[25,33],
  iconAnchor:[12,33]
})

export default class SimpleExample extends Component {
  state = {
    center:[44.3841670,20.6850566],    
    selectedMarker:"",
  }
zoom = 7;

// changePosition=(e)=>{
//   let newId = 1;
//   if(this.state.positions.length>0)
//    newId=this.state.positions[this.state.positions.length-1].id+1;
//   let newPosition = {id:newId,lat:e.latlng.lat,lng:e.latlng.lng,marker:markerIconSmall};
//   this.setState({
//     ...this.state,
//     positions:[...this.state.positions,newPosition]
//   })  
// }

changeZoom = (e)=>{
  this.zoom = e.target._zoom;
}

selectPosition = (id)=>{ 
  
  this.setState({
    ...this.state,    
    selectedMarker:id
  })
}

  render() {
     const {locations, onDelete, selectedMarker,onClick,onAdd360} = this.props;

    return (
      <Map center={this.state.center} zoom={this.zoom} onzoomend={e=>this.changeZoom(e)} maxZoom={19} preferCanvas={true} style={{position:"absolute", top:"0", left:"0", height:"100%", width:"100%"}}>
        <TileLayer
          attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
          url='https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=jMav2oontDrA10QYZ6op'
          crossOrigin={true}
        />
        {locations.map((loc)=><Marker key={loc.id} position={[loc.latitude, loc.longitude]} icon={selectedMarker===loc.id?markerIconLarge:markerIconSmall} onClick={()=>{onClick(loc.id)}}>
          <Popup>
            <DetailsCard cardTitle={loc.name} longitude={loc.longitude} latitude={loc.latitude} descriptionText={loc.description} onAdd360={onAdd360}/>
          </Popup>
          </Marker>
        )}
      </Map>
    )
  }
}