import React from 'react';
import styled from 'styled-components';

import IconDelete from '../../../resources/img/icon-delete.png';
import IconEdit from '../../../resources/img/icon-edit.png';

const ButtonWrapper = styled.div`
    display: inline-block;
    border-radius: 18px;
    overflow:hidden;
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    border: solid 1px #387f65;
    ${({margin="0"})=>`
    margin:${margin};
    `}
    
`
const ButtonPart = styled.button`
    display:inline-flex;
    align-items:center;
    justify-content:center;
    position:relative;
    background-color:#ffffff;
    height:36px;
    width:64px;
    border:none;
    outline:none;
    cursor: pointer;
    &:not(:last-child){
        &::after{
            content:"";
            position:absolute;
            display:block;
            top:5px;
            bottom:5px;
            right:0;
            width:2px;
            background-color:#2d7865;
        }
    }
`

const PopupButton = ({ margin, leftClickHandler=()=>{alert("Left Clicked")},rightClickHandler=()=>{alert("Right Clicked")}})=>{
    return(
        <ButtonWrapper margin={margin}>
            <ButtonPart onClick={()=>leftClickHandler()}>
                <img src={IconDelete} />
            </ButtonPart>
            <ButtonPart onClick={()=>rightClickHandler()}>
                <img src={IconEdit} />
            </ButtonPart>
        </ButtonWrapper>
    )
}

export default PopupButton;