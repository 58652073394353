import React, { Component } from 'react';
import {AxiosInstance} from '../Communication/CommApi';

const AdminContext = React.createContext();

class AdminContextProvider extends Component {
    getData = async()=>{
        try {
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            }
            const response = await AxiosInstance().get('/api/admin/data',config);

            this.setState({
                ...this.state,
                users: [...response.data.users],
                mapUrl:response.data.settings.mapUrl,
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't load users.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    }
    
    insertUser = async (user)=>{
        try {
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            }
            const response = await AxiosInstance().post('/api/admin/create-user',user,config);

            this.setState({
                ...this.state,
                users: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't create a user.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
        
    }

    updateUser = async (user)=>{
        try {
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            }
            const response = await AxiosInstance().post('/api/admin/update-user',user,config);

            this.setState({
                ...this.state,
                users: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't edit user.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
        
    }

    deleteUser = async (userId)=>{
        try {
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            }
            const response = await AxiosInstance().post('/api/admin/delete-user',{userId},config);

            this.setState({
                ...this.state,
                users: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't delete user.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    }

    handleRemoveError = () => {
        this.setState({
            ...this.state,
            hasError: false,
            errorMessage: ''
        })
    }

    state = {
        getData:this.getData,
        insertUser:this.insertUser,
        updateUser:this.updateUser,
        deleteUser:this.deleteUser,
        handleRemoveError: this.handleRemoveError,
        users: [],
        mapUrl:"",
        hasError:false,
        errorMessage: '',
        dataLoading:true
    }
    
    render() {
        return (
            <AdminContext.Provider value={this.state}>
                { this.props.children }
            </AdminContext.Provider>
        );
    }
}

const AdminContextConsumer = AdminContext.Consumer;

export { AdminContextProvider, AdminContext, AdminContextConsumer};