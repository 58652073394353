import React from 'react';
import styled from 'styled-components';

const Body = styled.tbody`

`;

const TableBody = ({children=null})=>{
    return(
        <Body>
            {children}
        </Body>
    )
}

export default TableBody;