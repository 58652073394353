import React, { Component } from 'react';
import styled from 'styled-components';
import {Switch, Burger, Breadcrumbs, UserMenu} from '../ui';
import IconLogo from '../../resources/img/logo.png';
import {NavContextConsumer} from '../../utility/Contexts/NavigationContext';
import { globalVariables } from '../../utility/common';
import { LocationContextConsumer } from '../../utility/Contexts/LocationContext';


const Wrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${globalVariables.headerHeight};
    background: #dbffed;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.13);
    z-index: 9998;
`

const LogoContainer = styled.div`
    display:inline-flex;
    position: relative;
    width: ${globalVariables.sideContentWidth};
    text-align: center;
    align-items: center;
    justify-content: center;
    &::after{
        content:"";
        position:absolute;
        top:15px;
        bottom:15px;
        right:0;
        width:1px;
        display:block;
        background-color: #2D7865;
    }
`

const Logo = styled.img`
    height: 48px;
    margin-right: 20px;
`

const LogoText = styled.p`
    width: 270px;
    font-size: 18px;
    color: #2d7865;
`
const HeadTools = styled.div`
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 0 40px;

    &::after{
        content:"";
        position:absolute;
        top:15px;
        bottom:15px;
        left:0;
        width:1px;
        display:block;
        background-color: #2D7865;
    }
`

const HeadContent = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-grow:1;
    flex-shrink:0;
`

class Header extends Component {
    state = {
        menuOpen: false
    }

    componentWillUnmount() {
        this.setState({
            ...this.state,
            menuOpen: false
        })
    }

    handleToggleMenu() {
        this.setState({
            ...this.state,
            menuOpen: !this.state.menuOpen
        })
    }

    render() {
        const { menuOpen } = this.state;
        
        return (
            <Wrapper>
                <LogoContainer>
                    <Logo src={IconLogo}/>
                    <LogoText>
                        Institut za nizijsko šumarstvo<br/>i životnu sredinu
                    </LogoText>
                </LogoContainer>
                <NavContextConsumer>
                    {({ onItemChange, pageName }) =>
                        <LocationContextConsumer>
                            {({breadCrumbs,getLocation})=>
                            <React.Fragment>
                                <HeadContent>
                                    <Breadcrumbs pageName={pageName} locations={breadCrumbs} onClick={getLocation}/>
                                </HeadContent>
                                <HeadTools>
                                    <Burger onClickHandler={() => this.handleToggleMenu()} active={menuOpen} />
                                    {menuOpen ? <UserMenu onChangeActiveItem={item=>{onItemChange(item); this.handleToggleMenu();}} /> : null}
                                </HeadTools>
                            </React.Fragment>
                            }
                        </LocationContextConsumer>                        
                    }
                </NavContextConsumer>
            </Wrapper>
        )
    }
}

export default Header;