import React from 'react';
import styled from 'styled-components';

const HeaderRow = styled.tr`
    height: 35px;
    color: #2d7865;
    background: #dbffed;
    user-select:none;
    & td:first-child {
        border-radius: 5px 0 0 0;
    }
    & td:last-child {
        border-radius: 0 5px 0 0;
    }
`;

const TableHeaderRow = ({children=null})=>{
    return(
        <HeaderRow>
            {children}
        </HeaderRow>
    )
}

export default TableHeaderRow;