import React, {Component} from 'react';
import * as THREE from 'three';
import portalIcon from '../../resources/img/icon-portal.png';
import poiIcon from '../../resources/img/icon-poi.png';
import transparentImage from '../../resources/img/transparentImg.png';
import playButton from '../../resources/img/play button.png';
import audioOn from '../../resources/img/AudioOn.png';
import audioOff from '../../resources/img/AudioOff.png';
import arrowUp from '../../resources/img/ArrowUp.png';
import arrowDown from '../../resources/img/ArrowDown.png';
import audioButton from '../../resources/img/AudioOff.png';
import config from '../../config/default.json';

const wrap_text = (ctx, text, maxWidth, textAlign, fontSize, fontFace) => {
    if(text.length===0) return [];
    if(!textAlign) textAlign = 'center';
    ctx.textAlign = textAlign;
    ctx.font = `normal ${fontSize}px ${fontFace}`;

    const words = text.split(' ');
    let lines = [];
    let sliceFrom = 0;
    for(let i = 0; i < words.length; i++) {
        const chunk = words.slice(sliceFrom, i).join(' ');
        const last = i === words.length - 1;
        const bigger = ctx.measureText(chunk).width > maxWidth;

        if(bigger) {
            lines.push(words.slice(sliceFrom, i-1).join(' '));
            sliceFrom = i-1;
        }
        if(last) {
            lines.push(words.slice(sliceFrom, words.length).join(' '));
        }
    }
    return lines;
};

const paginator = (items, page=1, itemsPerPage=10) =>{

    let offset = (page - 1) * itemsPerPage;
    let paginatedItems = items.slice(offset).slice(0, itemsPerPage);
    let totalPages = Math.ceil(items.length / itemsPerPage);
    return {
        currentPage: page,
        itemsPerPage: itemsPerPage,
        previousPage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        totalItems: items.length,
        totalPages: totalPages,
        pageItems: paginatedItems
    };
};


const createElement = (type, data, parameters) => {
    //get context
    let context;
    if(data.redraw){
        context =  data.context;
    }else{
        context =  document.createElement('canvas').getContext('2d');
    }

    //parse parameters
    if (parameters === undefined) parameters = {};

    const fontFace = parameters.hasOwnProperty("fontFace") ?
        parameters["fontFace"] : "Arial";

    const fontsize = parameters.hasOwnProperty("fontsize") ?
        parameters["fontsize"] : 18;

    const backgroundColor = parameters.hasOwnProperty("backgroundColor") ?
        parameters["backgroundColor"] : {r: 255, g: 255, b: 255, a: 1};

    const defaultCanvasWidth = parameters.hasOwnProperty("canvasWidth") ?
        parameters["canvasWidth"] : 512;
    //minimum canvas height
    const canvasMinHeight = 64;

    switch (type) {
        case 'text': {
            context.clearRect(0,0,context.canvas.width,context.canvas.height);
            const lines = wrap_text(context, data.text, 440, 'left', fontsize, fontFace);
            //set canvas properties for background panel
            context.canvas.width = defaultCanvasWidth;
            context.canvas.height = lines.length>0 ? 256 : canvasMinHeight;

            context.font = `normal ${fontsize}px ${fontFace}`;
            context.imageSmoothingEnabled = true;
            context.fillStyle = `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`;
            context.strokeStyle = `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`;
            //create background panel
            createRect(context, 0, 0, context.canvas.width, context.canvas.height);

            //set canvas props for text
            context.fillStyle = "rgba(255, 255, 255, 1)";
            context.strokeStyle = "rgba(255, 255, 255, 1)";

            //put heading (we know that text, if exists, is the heading owner
            context.fillText(data.heading.toUpperCase(), 20, 36);
            context.font = `normal ${fontsize}px ${fontFace}`;
            //put the rest of the text
            let offsetY = 0;
            let offsetX = 0;
            // if(textAlign === 'center') offsetX = maxWidth / 2
            //Text Pagination

            let pagination = paginator(lines,data.page || 1,9);

            for (let i = 0; i < pagination.pageItems.length; i++) {
                context.fillText(pagination.pageItems[i], 52 + offsetX, 75 + offsetY);
                offsetY = offsetY + 22
            }

            return {context,pagination};
        }
        case 'video': {

            //set canvas properties for background panel
            const video = document.createElement("video");
            video.src = `${config.serverUrl}${data.video}`;
            video.crossOrigin = "anonymous";
            video.load();
            video.play();
            return video;
        }
        case 'audio': {
            context.canvas.width = defaultCanvasWidth;
            context.canvas.height = data.canvasHeight || canvasMinHeight;
            context.imageSmoothingEnabled = true;
            context.fillStyle = `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`;
            context.strokeStyle = `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`;
            //create background panel
            createRect(context, 0, 0, context.canvas.width, context.canvas.height);

            return context;
        }
    }
};

const createRect = (ctx, x, y, w, h)=>{
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x+w, y);
    ctx.lineTo(x+w, y+h);
    ctx.lineTo(x, y+h);
    ctx.lineTo(x, y);
    ctx.closePath();
    ctx.fill();
	ctx.stroke();
};

class Photo360Engine extends Component{

    createSkybox = (imageUrl) =>{
        //add sphere for 360
        const sphere = new THREE.SphereGeometry(100, 100, 40);
        sphere.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
        //create material
        const material = new THREE.MeshBasicMaterial();
        //add image to material
        const loader = new THREE.TextureLoader();
        loader.setCrossOrigin("");
        material.map = loader.load(imageUrl);
        const skybox = new THREE.Mesh(sphere, material);
        skybox.name = "Skybox";
        return skybox;
    };

    createTransparentSphere = ()=>{
        const transparentSphere = new THREE.SphereGeometry(25, 40, 40);
        transparentSphere.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
        //create material
        const transparentMaterial = new THREE.MeshBasicMaterial({
            opacity: 0,
            transparent: true,
            premultipliedAlpha: true
        });
        //add image to material
        transparentMaterial.map = new THREE.TextureLoader().load(transparentImage);
        //merge transparentMaterial and transparentSphere
        const tSphere = new THREE.Mesh(transparentSphere, transparentMaterial);
        tSphere.name = "Transparent Sphere";
        return tSphere;
    };

    componentDidMount(){
        //global variables init
        this.latitude = 0;
        this.longitude = 0;
        this.width = this.mount.offsetWidth;
        this.height = this.mount.offsetHeight;
        this.scaleCoef = 1;

        //create skybox
        this.skyBox = this.createSkybox(`${config.serverUrl}${this.props.image}`);
        //add sphere to scene
        this.transparentSphere = this.createTransparentSphere();

        //add camera
        this.initCamera();
        //add scene
        this.setScene();

        //add renderer
        this.renderer = new THREE.WebGLRenderer({antialias: true});

        this.renderer.setSize(this.width, this.height);
        this.renderer.setPixelRatio(window.devicePixelRatio);

        this.renderer.localClippingEnabled = false;


        this.mount.appendChild(this.renderer.domElement);

        window.addEventListener('resize', this.handleResize);

        this.animate();
    }

    initCamera = () => {
        this.camera = new THREE.PerspectiveCamera(60, this.width / this.height, 1, 1000);
    };

    setScene = ()=>{
        this.scene = new THREE.Scene();
        this.scene.children = [];
        if(this.portalGroup) this.portalGroup.children = [];
        if(this.poiGroup) this.poiGroup.children = [];
        if(this.poiInfoGroup) this.poiInfoGroup.children = [];

        this.scene.add(this.transparentSphere);
        this.scene.add(this.skyBox);
        this.scene.add(this.camera);

        this.props.portals.map(portal => {
            if (portal.position.x)
                this.placeIcons(portal.position, {id: portal.id, name: portal.name}, 'portal');
        });

        this.props.pois.map(poi => {
            if (poi.position.x)
                this.placeIcons(poi.position, {id: poi.id, name: poi.name}, 'poi');
        });
    };

    componentWillReceiveProps(nextProps){

        if(JSON.stringify(this.props.portals)!==JSON.stringify(nextProps.portals)){
            if(this.portalGroup)
                this.portalGroup.children = [];
            if(this.poiInfoGroup)
                this.poiInfoGroup.children = [];
            nextProps.portals.map(portal=>{
                if(portal.position && portal.position.x)
                    this.placeIcons(portal.position,{id:portal.id,name:portal.name},'portal');
            });
            if(this.currentAudio && this.currentAudio.player) {
                this.currentAudio.player.pause();
                this.currentAudio.isPlaying = false;
            }

            if(this.currentVideo) this.currentVideo.pause();
        }
        if(JSON.stringify(this.props.pois)!==JSON.stringify(nextProps.pois)){
            if(this.poiGroup)
                this.poiGroup.children = [];
            if(this.poiInfoGroup)
                this.poiInfoGroup.children = [];
            nextProps.pois.map(poi=>{
                if(poi.position && poi.position.x)
                    this.placeIcons(poi.position,{id:poi.id,name:poi.name},'poi');

            });
            if(this.currentAudio && this.currentAudio.player) {
                this.currentAudio.player.pause();
                this.currentAudio.isPlaying = false;
            }

            if(this.currentVideo) this.currentVideo.pause();
        }
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize);
        this.stop();
        if(this.currentAudio && this.currentAudio.player) this.currentAudio.player.pause();
        if(this.currentVideo) this.currentVideo.pause();

        if(this.poiInfoGroup) this.poiInfoGroup.children = [];

        this.mount.removeChild(this.renderer.domElement);
    }

    stop = () => {
        this.renderer.setAnimationLoop(null);
    };

    handleResize = () => {

            this.scaleCoef = 1;


        if(this.poiInfoGroup) this.poiInfoGroup.children=[];
        let width = this.mount.clientWidth;
        let height = this.mount.clientHeight;
        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderScene();
    };

    animate = ()=>{
        let v3 = new THREE.Vector3(
            500 * Math.sin(THREE.MathUtils.degToRad(90 - this.latitude)) * Math.cos(THREE.MathUtils.degToRad(this.longitude)),
            500 * Math.cos(THREE.MathUtils.degToRad(90 - this.latitude)),
            500 * Math.sin(THREE.MathUtils.degToRad(90 - this.latitude)) * Math.sin(THREE.MathUtils.degToRad(this.longitude))
        );

        this.camera.lookAt(v3);


        v3 = null;
        this.renderScene();

        this.renderer.setAnimationLoop(this.animate);
    };

    renderScene = ()=>{
        this.renderer.render(this.scene, this.camera);
    };

    //#region mouse events
    handleMouseDown=(event)=>{
        event.preventDefault();
        
       this.manual = true;
       this.savedX = event.clientX;
       this.savedY = event.clientY;
  
        this.savedLongitude = this.longitude;
        this.savedLatitude = this.latitude;
    };

    handleMouseMove = (event)=>{
        if(this.manual){ 
          this.longitude = (this.savedX - event.clientX) * 0.1 + this.savedLongitude;
          this.latitude = (event.clientY - this.savedY) * 0.1 + this.savedLatitude;                  
        }      
    };

    handleMouseUp = (event)=>{
        this.manual=false;
    };
    //#endregion

    handleTouchStart = (event)=>{
        this.manual = true;
        this.savedX = event.changedTouches[0].clientX;
        this.savedY = event.changedTouches[0].clientY;
        
        this.savedLongitude = this.longitude;
        this.savedLatitude = this.latitude;      
    };

    handleTouchMove = (event)=>{
        if(this.manual){ 
            this.longitude = (this.savedX - event.changedTouches[0].clientX) * 0.1 + this.savedLongitude;
            this.latitude = (event.changedTouches[0].clientY - this.savedY) * 0.1 + this.savedLatitude;        
        } 
    };

    handleTouchEnd = (event)=>{        
        this.manual=false;      
    };

    handleDragOver = (e)=>{
        e.preventDefault();
    };

    handleDrop = e=>{
        const itemId = e.dataTransfer.getData("id");
        const name = e.dataTransfer.getData("name");
        const type = e.dataTransfer.getData("type");
        if(!itemId || !type) return;
        const interSects = this.castScreenToSphere(e); 
        if (interSects.length > 0) {
            switch (type) {
                case "portal":
                    this.placeIcons(interSects[0].point,{id:itemId,name},'portal');
                    this.props.onPortalDropped({portalId:itemId,x:interSects[0].point.x,y:interSects[0].point.y,z:interSects[0].point.z});
                    break;            
                case "poi":
                    this.placeIcons(interSects[0].point,{id:itemId,name},'poi');
                    this.props.onPoiDropped({poiId:itemId,x:interSects[0].point.x,y:interSects[0].point.y,z:interSects[0].point.z});
                    break;            
            }
        }
    };

    getRaycast = (event) =>{
        const mouse2D = new THREE.Vector2();
        mouse2D.x = ((event.clientX-this.mount.getBoundingClientRect().left) / this.mount.offsetWidth) * 2 - 1;
        mouse2D.y = -((event.clientY-this.mount.getBoundingClientRect().top) /this.mount.offsetHeight) * 2 + 1;

        //const ray = new THREE.Raycaster(this.camera.position, mouse3D.sub(this.camera.position).normalize());
        const ray = new THREE.Raycaster();
        ray.setFromCamera(mouse2D, this.camera);
        return ray;
    };

    turnMediaOff = () => {
        //ako video postoji i pusten je, gasim
        if(this.currentVideo){
            this.currentVideo.pause();
        }
        if(this.currentAudio){
            this.currentAudio.player.pause();
            this.currentAudio.isPlaying = false;
        }
    };

    pageUp = () =>{
        if(this.textData && this.textData.pagination && this.textData.pagination.previousPage!=null){
            const canvasParameters = {fontFace:'Open Sans', fontsize: 16, borderThickness:0, backgroundColor:{r:45,g:120,b:101,a:0.4}, canvasWidth: 512};
            //pozovi create text canvas sa novim parametrima
            const textData = createElement('text', {heading: this.textData.heading, text: this.textData.text, page: this.textData.pagination.previousPage, redraw:true,context:this.textData.context}, canvasParameters);


            //kreiraj novu teksturu od novog canvasa
            this.textCanvasTexture.needsUpdate = true;

            let newTextData = {...this.textData};
            newTextData.pagination = textData.pagination;
            this.textData = {...newTextData};
        }
    };

    pageDown = () =>{
        if(this.textData && this.textData.pagination && this.textData.pagination.nextPage!=null){
            const canvasParameters = {fontFace:'Open Sans', fontsize: 16, borderThickness:0, backgroundColor:{r:45,g:120,b:101,a:0.4}, canvasWidth:512};
            const textData = createElement('text', {heading: this.textData.heading, text: this.textData.text, page: this.textData.pagination.nextPage,redraw:true,context:this.textData.context}, canvasParameters);

            //kreiraj novu teksturu od novog canvasa
            this.textCanvasTexture.needsUpdate = true;

            let newTextData = {...this.textData};
            newTextData.pagination = textData.pagination;
            this.textData = {...newTextData};
        }
    };



    handleClick = event =>{
        const ray = this.getRaycast(event);
        let portalInterSects = null;
        let poiInterSects = null;
        let audioButtonInterSects = null;
        let pageUpInterSects = null;
        let pageDownInterSects = null;

        if(this.portalGroup && this.portalGroup.children.length>0)
            portalInterSects= ray.intersectObjects(this.portalGroup.children,true);

        if(this.poiGroup && this.poiGroup.children.length>0)
            poiInterSects = ray.intersectObjects(this.poiGroup.children,true);

        if(this.audioButtonSprite){
            audioButtonInterSects = ray.intersectObject(this.audioButtonSprite,true);
        }

        if(this.pageUpSprite){
            pageUpInterSects = ray.intersectObject(this.pageUpSprite,true);
        }

        if(this.pageDownSprite){
            pageDownInterSects = ray.intersectObject(this.pageDownSprite,true);
        }

        //click on the portal icon
        if(portalInterSects && portalInterSects.length>0){
            this.turnMediaOff();
            const portalData = JSON.parse(portalInterSects[0].object.name);
            this.props.onPortalClicked({id:portalData.id,name:portalData.name});

        }

        //click on the poi icon
        else if (poiInterSects && poiInterSects.length>0){
            const poiData = JSON.parse(poiInterSects[0].object.name);
            //Info panel postoji
            if(this.poiInfoGroup && this.poiInfoGroup.children.length>0){
                const infoPanelData = JSON.parse(this.poiInfoGroup.children[0].userData);
                //kliknuto je na vec otvoren poi
                if(poiData.id===infoPanelData.id){
                    //zatvaram poi info panel
                    this.poiInfoGroup.children= [];
                    this.turnMediaOff();
                    return;
                }
                this.turnMediaOff();
            }
            
            const poiIndex = this.props.pois.findIndex(poi=>poi.id===poiData.id);
            if(poiIndex<0)return;
            const poiFullData = this.props.pois[poiIndex];

            this.createInfoPanel(poiFullData);
            //this.createPoiPanel(poiFullData)
        }

        else if (audioButtonInterSects && audioButtonInterSects.length>0){
            let objectData = JSON.parse(audioButtonInterSects[0].object.name);
            this.toggleAudio(objectData);
        }

        else if (pageUpInterSects && pageUpInterSects.length>0){
            this.pageUp();
        }

        else if (pageDownInterSects && pageDownInterSects.length>0){
            this.pageDown();
        }
    };

    toggleAudio = (audioData) =>{
        if(this.currentAudio){
            if(this.currentAudio.id === audioData.file){
                if (this.currentAudio.isPlaying) {
                    this.audioButtonMaterial.map = this.audioButtonOffTexture;
                    this.currentAudio.player.pause();
                    this.currentAudio.isPlaying = false;
                } else {
                    this.audioButtonMaterial.map = this.audioButtonOnTexture;
                    this.currentAudio.player.play();
                    this.currentAudio.isPlaying = true;
                }
                return;
            }
        }

        this.currentAudio = {
            player: new Audio(`${config.serverUrl}${audioData.file}`)  ,
            id:audioData.file,
            isPlaying:false
        };
        this.audioButtonMaterial.map = this.audioButtonOnTexture;
        this.currentAudio.player.play();
        this.currentAudio.isPlaying = true;
        this.currentAudio.player.onended = () => {
            this.audioButtonMaterial.map = this.audioButtonOffTexture;
            this.currentAudio.isPlaying = false;            
        }

    };

    castScreenToSphere=(event)=>{
        const ray = this.getRaycast(event);
        return ray.intersectObjects(this.scene.children, true);
    };

    placeIcons = (location, data, type) =>{
        const position =new THREE.Vector3(location.x,location.y,location.z);
        const spriteMap = new THREE.TextureLoader().load( type==='portal'?portalIcon:poiIcon );

        const spriteMaterial = new THREE.SpriteMaterial( { map: spriteMap, color: 0xffffff } );
        const sprite = new THREE.Sprite( spriteMaterial );

        sprite.name = JSON.stringify(data);
        sprite.position.set(position.x, position.y, position.z);
        sprite.scale.set(2, 2, 1);
        sprite.position.multiplyScalar(0.9);

        if(type==='portal'){
            if(!this.portalGroup) this.portalGroup = new THREE.Group();
            this.portalGroup.name = "Portal Group";
            this.portalGroup.add(sprite);
            this.scene.add(this.portalGroup);
        }else if(type==='poi'){
            if(!this.poiGroup) this.poiGroup = new THREE.Group();
            this.poiGroup.name = "Poi Group";
            this.poiGroup.add(sprite);
            this.scene.add(this.poiGroup);
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////

    getGazedPoiIcon = (anot) =>{
        const position =new THREE.Vector3(anot.x,anot.y,anot.z);
        //
         const cameraProjectedPosition = position.project(this.camera);
        // const newVector = cameraProjectedPosition.unproject(this.camera);

        const ray = new THREE.Raycaster();
        //const ray = new THREE.Raycaster(this.camera.position, position.sub(this.camera.position).normalize());
        ray.setFromCamera(cameraProjectedPosition,this.camera);
        return ray.intersectObjects(this.scene.children,true);
    };

    createSprite = (img) =>{
        let image = new THREE.TextureLoader().load(img);
        let imageMaterial = new THREE.SpriteMaterial( { map: image, color: 0xffffff  } );
        return new THREE.Sprite( imageMaterial );
    };

    createInfoPanel = (poiData) =>{
        let textMesh;
        let videoMesh;
        let audioMesh;
        const canvasWidth = 512;
        let text = poiData.text;
        let video = poiData.video;
        let audio = poiData.audio;
        const canvasParameters = {fontFace:'Open Sans', fontsize: 16, borderThickness:0, backgroundColor:{r:45,g:120,b:101,a:0.4}, canvasWidth};
        const res = this.getGazedPoiIcon(poiData.position);

        const textData = createElement('text', {heading: poiData.name, text: text.enabled ? text.description : ""}, canvasParameters);
        const textCanvasContext = textData.context;

        this.textCanvasTexture = new THREE.CanvasTexture(textCanvasContext.canvas);

        this.textCanvasTexture.needsUpdate = true;
        this.textMaterial = new THREE.MeshBasicMaterial({
            map:this.textCanvasTexture
        });

        const panelWidth = this.scaleCoef*10;
        //izračunaj visinu panela na osnovu canvasa
        const panelHeight = panelWidth / canvasWidth * textCanvasContext.canvas.height;

        //napravi geometriju panela
        const textInfoPanel = new THREE.PlaneBufferGeometry(panelWidth,panelHeight);
        if(this.scaleCoef===1)
            textInfoPanel.translate(7,-(panelHeight)/2,0);
        else
            textInfoPanel.translate(13,-(panelHeight)/2,0);

        //napravi mesh za tekst
        textMesh = new THREE.Mesh(textInfoPanel,this.textMaterial);
        textMesh.applyQuaternion(this.camera.quaternion);

        //pozicioniraj mash na osnovu raycast-a
        textMesh.position.set(res[0].point.x, res[0].point.y, res[0].point.z);
        textMesh.updateMatrix();

        //ubaci user data za dalju logiku
        textMesh.userData = JSON.stringify(poiData);
        textMesh.geometry.computeBoundingBox();

        //posto znam da je tekst uvek prvi, iniciram grupu na njemu
        if(!this.poiInfoGroup) {
            this.poiInfoGroup = new THREE.Group();
            this.poiInfoGroup.name ="Poi Info Group";
        }
        else this.poiInfoGroup.children=[];

        this.poiInfoGroup.add(textMesh);

        if(text.enabled && textData.pagination.nextPage!==null){
            this.textData = {
                heading: poiData.name,
                text: text.description,
                pagination: textData.pagination,
                context:textData.context
            };
            this.pageUpSprite = this.createSprite(arrowUp);
            this.pageUpSprite.name = "Page Up Sprite";
            this.pageUpSprite.scale.set(0.75, 0.75, 1);
            this.pageUpSprite.position.multiplyScalar(0.9);
            console.log(panelWidth);
            if(this.scaleCoef===1)
                this.pageUpSprite.applyMatrix4(new THREE.Matrix4().makeTranslation(7 - panelWidth/2.25 , textMesh.geometry.boundingBox.min.y*0.51, 0.5));
            else
                this.pageUpSprite.applyMatrix4(new THREE.Matrix4().makeTranslation(13 - panelWidth/2.2 , textMesh.geometry.boundingBox.min.y*0.51, 0.5));

            this.pageUpSprite.applyMatrix4(textMesh.matrix);
            this.poiInfoGroup.add(this.pageUpSprite);


            this.pageDownSprite = this.createSprite(arrowDown);
            this.pageDownSprite.name = "Page Down Sprite";
            this.pageDownSprite.scale.set(0.75, 0.75, 1);
            this.pageDownSprite.position.multiplyScalar(0.9);

            if(this.scaleCoef===1)
                this.pageDownSprite.applyMatrix4(new THREE.Matrix4().makeTranslation(7- panelWidth/2.25 , textMesh.geometry.boundingBox.min.y*0.72, 0.5));
            else
                this.pageDownSprite.applyMatrix4(new THREE.Matrix4().makeTranslation(13 - panelWidth/2.2 , textMesh.geometry.boundingBox.min.y*0.72, 0.5));

            this.pageDownSprite.applyMatrix4(textMesh.matrix);
            this.poiInfoGroup.add(this.pageDownSprite);

        }

        if(audio.enabled){
            const audioCanvasContext = createElement('audio', {}, canvasParameters);

            const audioCanvasTexture = new THREE.Texture(audioCanvasContext.canvas);
            audioCanvasTexture.needsUpdate = true;

            const audioCanvasMaterial = new THREE.MeshBasicMaterial({
                map:audioCanvasTexture, color: 0xffffff
            });

            //izračunaj širinu panela na osnovu canvasa
            const panelWidth = this.scaleCoef*10;
            const panelHeight = panelWidth / canvasWidth * audioCanvasContext.canvas.height;

            //napravi geometriju panela
            const audioInfoPanel = new THREE.PlaneBufferGeometry(panelWidth,panelHeight);
            if(this.scaleCoef===1)
                audioInfoPanel.translate(7,-(panelHeight)/2,0);
            else
                audioInfoPanel.translate(13,-(panelHeight)/2,0);

            //napravi mesh za tekst
            audioMesh = new THREE.Mesh(audioInfoPanel,audioCanvasMaterial);


            this.audioButtonOnTexture = new THREE.TextureLoader().load(audioOn);
            this.audioButtonOffTexture = new THREE.TextureLoader().load(audioOff);

            this.audioButtonMaterial = new THREE.SpriteMaterial( { map: this.audioButtonOffTexture, color: 0xffffff  } );
            this.audioButtonSprite = new THREE.Sprite( this.audioButtonMaterial );

            this.audioButtonSprite.name = JSON.stringify(audio);
            this.audioButtonSprite.scale.set(0.75, 0.75, 1);
            this.audioButtonSprite.position.multiplyScalar(0.9);
            //this.audioButtonSprite.geometry.computeBoundingBox();


            audioMesh.applyMatrix4(new THREE.Matrix4().makeTranslation(0, textMesh.geometry.boundingBox.min.y, 0));


            // apply transforms of mesh on top
            audioMesh.applyMatrix4(textMesh.matrix);
            audioMesh.geometry.computeBoundingBox();
            if(this.scaleCoef===1)
                this.audioButtonSprite.applyMatrix4(new THREE.Matrix4().makeTranslation(7 , -panelHeight/2, 0.5));
            else
                this.audioButtonSprite.applyMatrix4(new THREE.Matrix4().makeTranslation(13 , audioMesh.geometry.boundingBox.min.y/2+0.25, 0.5));

            this.audioButtonSprite.applyMatrix4(audioMesh.matrix);

            audioMesh.userData = JSON.stringify(poiData);

            this.poiInfoGroup.add(audioMesh);
            this.poiInfoGroup.add(this.audioButtonSprite);
        }
        if(video.enabled){
            //napravi canvas
            this.currentVideo = createElement('video', {heading: poiData.name, video: video.file}, canvasParameters);

            //napravi teksturu od kanvasa
            const videoTexture = new THREE.VideoTexture(this.currentVideo);
            //materijal napravljen od teksture
            const videoMaterial = new THREE.MeshBasicMaterial({
                map:videoTexture,
                color: 0xffffff
            });

            //širina panela
            const panelWidth = this.scaleCoef*10 ;
            const panelHeight = this.scaleCoef*video.videoHeight*10/video.videoWidth;
            //napravi geometriju panela
            const videoPanel = new THREE.PlaneBufferGeometry(panelWidth,panelHeight);
            if(this.scaleCoef===1)
                videoPanel.translate(7,-(panelHeight)/2,0);
            else
                videoPanel.translate(13,-(panelHeight)/2,0);

            //napravi mesh za video
            videoMesh = new THREE.Mesh(videoPanel,videoMaterial);

            //ako je audio aktivan zalepi za audio
            if(audio.enabled){
                videoMesh.applyMatrix4(new THREE.Matrix4().makeTranslation(0, audioMesh.geometry.boundingBox.min.y, 0));
                videoMesh.applyMatrix4(audioMesh.matrix);
            }else{
                //ako je audio neaktivan, proveriti da li je text tu
                videoMesh.applyMatrix4(new THREE.Matrix4().makeTranslation(0, textMesh.geometry.boundingBox.min.y, 0));

                // apply transforms of mesh on top
                videoMesh.applyMatrix4(textMesh.matrix);
            }

            //ubaci user data za dalju logiku
            videoMesh.userData = JSON.stringify(poiData);

            this.poiInfoGroup.add(videoMesh);
        }
        this.scene.add(this.poiInfoGroup);

    };
    ////////////////////////////////////////////////////////////////
   render(){
        return(
            <div style={{height:"100%",width:"100%",overflow:"hidden"}}
                onMouseDown={(e)=>this.handleMouseDown(e)}
                onMouseMove={(e)=>this.handleMouseMove(e)}
                onMouseUp={(e)=>this.handleMouseUp(e)}
                onTouchStart={(e)=>this.handleTouchStart(e)}
                onTouchMove={(e)=>this.handleTouchMove(e)}
                onTouchEnd={(e)=>this.handleTouchEnd(e)}
                onDragOver={(e)=>this.handleDragOver(e)}
                onDrop ={(e)=>this.handleDrop(e)}
                onClick = {(e)=>this.handleClick(e)}
                ref={(mount) => { this.mount = mount }}
            />
        )
    }
}
export default Photo360Engine;