import React, {Component} from 'react';
import styled from 'styled-components';
import { Modal, Input, Frame, Switch} from '../index';
import { TextIcon, AudioIcon, VideoIcon } from '../Icons';
import config from '../../../config/default';


const OptionsWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    top: 19px;
    right 8px;
`;

const HidenFileInput = styled.input`
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

const ReuploadButton = styled.label`
    font-size: 16;
    color: #fff;
    margin: 0 20px 0 0;
    font-weight: 400;
    display: inline-block;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
`;

const UploadButton = styled.label`
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    ${({margin="0"})=>`
    margin:${margin};
    `}
    min-width: 40px;
    height: 36px;
    padding: 0 15px;
    font-size: 16px;
    text-transform:uppercase;
    letter-spacing: -0.8px;
    color: #2d7865;
    background-color:#fff;
    border-radius: 40px;
    border: solid 1px #387f65;
    outline: none;
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    vertical-align: middle;
    ${({disabled}) => disabled === true
        ?   `pointer-events: none;
            color: #a9a9a9;
            background-color: #dedede;
            border-color: transparent;
            cursor: not-allowed;
            box-shadow: none;`
        :   `&:hover {
                color: #ffffff;
                background-color: #2D7865;
            }`
    }
`;

const FrameContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 22px);
`;

const MediaWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    margin-top: auto;
`;

const MediaLabel = styled.span`
    position: absolute;
    bottom: calc(100% + 5px);
    left: 15px;
    color: #ffffff;
`;

const StyledAudio = styled.audio`
    width: 100%;
    height: 28px;
`;

const StyledVideo = styled.video`
    width: 100%;
    height: 240px;
    background: black;
    border-radius: 20px;
`;

const MessageLabel = styled.span`
    position: absolute;
    top: 12px;
    left: 50%;
    font-size: 14px;
    transform: translateX(-50%);
    color: #ffd22e;
`;

class PoiManager extends Component {
    constructor(props) {
        super(props);

        if(this.props.poiData){
            let poiData = this.props.poiData;
            this.state={
                mode:'edit',
                poiId:poiData.id,
                poiName: poiData.name,
                formFields: {
                    text: {
                        enabled: poiData.text.enabled,
                        description: poiData.text.description,
                    },
                    audio:{
                        enabled: poiData.audio.enabled,
                        file: poiData.audio.enabled?`${config.serverUrl}${poiData.audio.file}`:null,
                        originalFileName: poiData.audio.enabled?poiData.audio.originalFilename:null
                    },
                    video: {
                        enabled: poiData.video.enabled,
                        file: poiData.video.enabled?`${config.serverUrl}${poiData.video.file}`:null,
                        originalFileName: poiData.video.enabled?poiData.video.originalFilename:null
                    },
                },
                audioFile:null,
                audioSizeValid: true,
                videoFile:null,
                videoSizeValid: true,
                videoWidth:poiData.video.videoWidth,
                videoHeight:poiData.video.videoHeight,
                formValid:false,
                touchedFields:{
                    name:false,
                    text:false,
                    audio:false,
                    audioFile:false,
                    video:false,
                    videoFile:false
                }
            }
        }else{
            this.state = {
                mode:'new',
                poiId:null,
                poiName: '',
                formFields: {
                    text: {
                        enabled: true,
                        description: ''
                    },
                    audio: {
                        enabled: false,
                        file: null,
                        originalFileName: null
                    },
                    video: {
                        enabled: false,
                        file: null,
                        originalFileName: null
                    },
                },
                audioFile:null,
                audioSizeValid: true,
                videoFile:null,
                videoSizeValid: true,
                videoWidth:null,
                videoHeight:null,
                formValid:false,

            };
        }
    }

     validateForm = () => {
        let formValid = true;
        let text = this.state.formFields.text;
        let audio = this.state.formFields.audio;
        let video = this.state.formFields.video;

        let touchedFields = {
            name:false,
            text:false,
            audio:false,
            video:false,
        };

        // ako bilo koji od prop nije setovan
        try {
            //poi name mora biti setovan
            if (this.state.poiName.length <= 2) {
                formValid = false;
            }
            if(this.state.mode==='edit'){
                touchedFields.name = this.state.poiName !== this.props.poiData.name;
            }
            //bar jedan tip contenta mora biti enable
            if(formValid && (text.enabled || audio.enabled || video.enabled)){
                //ako je enable onda mora biti i setovan
                if(text.enabled){
                    //text description max 500 char
                    if(text.description){
                        if(text.description.length<3 || text.description.length>1000){
                            formValid = false;
                        }else{
                            if(this.state.mode==='edit'){
                                touchedFields.text = text.enabled !== this.props.poiData.text.enabled || text.description !== this.props.poiData.text.description;
                            }
                        }
                    }else{
                        formValid = false;
                    }

                }
                if(audio.enabled){
                    if(!audio.file || audio.file.length===0) formValid = false;
                    if(this.props.poiData && this.props.poiData.audio.enabled === false){
                        touchedFields.audio = true;
                    }else{
                        touchedFields.audio = false;
                    }
                    //audio max size 10 MB?
                }else{
                    if(this.props.poiData && this.props.poiData.audio.enabled === true){
                        touchedFields.audio = true;
                    }else{
                        touchedFields.audio = false;
                    }
                }
                if(video.enabled){
                    if(!video.file || video.file.length===0) formValid = false;
                    if(this.props.poiData && this.props.poiData.video.enabled === false){
                        touchedFields.video = true;
                    }else{
                        touchedFields.video = false;
                    }
                    //video max size 50 MB?
                }else{
                    if(this.props.poiData && this.props.poiData.video.enabled === true){
                        touchedFields.video = true;
                    }else{
                        touchedFields.video = false;
                    }
                }
            }
            else{
                formValid = false;
            }
        }catch(error){
            console.log(error);
            formValid = false;
        }
        if(this.state.mode ==='edit'){
            if(this.state.touchedFields.audioFile) touchedFields.audio = true;
            if(this.state.touchedFields.videoFile) touchedFields.video = true;


            formValid = formValid && (touchedFields.name||touchedFields.text||touchedFields.audio||touchedFields.video);

            let newTouchedFields = {...this.state.touchedFields, ...touchedFields};

            this.setState({...this.state, formValid : formValid, touchedFields:{...newTouchedFields}});
        }else{
            this.setState({...this.state, formValid : formValid});
        }
    };



    poiNameChangedHandler = (event) => {

        this.setState({
            ...this.state,
            poiName: event.target.value
        },()=>{
            this.validateForm();
        });
    };

    textChangedHandler = (event) => {
        let newformFields = JSON.parse(JSON.stringify(this.state.formFields));
        newformFields.text.description = event.target.value;

        this.setState({
            ...this.state,
            formFields: newformFields
        },()=>{
            this.validateForm();
        })
    };

    onChooseAudioHandler = (event) => {
        let newformFields = JSON.parse(JSON.stringify(this.state.formFields));
        let audioFile = event.target.files[0];
        newformFields.audio.file = URL.createObjectURL(audioFile);
        newformFields.audio.originalFileName = event.target.files[0].name;

        if (audioFile.size > 6291456) {
            this.setState({
                ...this.state,
                audioSizeValid: false,
            });
            return;
        }

        if(this.state.mode==='edit'){
            let touchedFields = {...this.state.touchedFields};
            touchedFields.audioFile = true;
            this.setState({
                ...this.state,
                formFields: newformFields,
                audioFile: audioFile,
                touchedFields,
                audioSizeValid: true
            },()=>{
                this.validateForm();
            });
            return;
        }

        this.setState({
          ...this.state,
            formFields: newformFields,
            audioFile: audioFile,
            audioSizeValid: true
        },()=>{
            this.validateForm();
        })
    };
    handleVideoMetadata = (event) =>{

       this.setState({
           ...this.state,
           videoHeight:event.target.videoHeight,
           videoWidth:event.target.videoWidth
       })
    };

    onChooseVideoHandler = (event) => {
        let newformFields = JSON.parse(JSON.stringify(this.state.formFields));
        let videoFile = event.target.files[0];
        newformFields.video.file = URL.createObjectURL(videoFile);
        newformFields.video.originalFileName = videoFile.name;

        if (videoFile.size > 20971520) {
            this.setState({
                ...this.state,
                videoSizeValid: false,
            });
            return;
        }

        if(this.state.mode==='edit'){
            let touchedFields = {...this.state.touchedFields};
            touchedFields.videoFile = true;
            this.setState({
                ...this.state,
                formFields: newformFields,
                videoFile: videoFile,
                videoSizeValid: true,
                touchedFields
            },()=>{
                this.validateForm();
            });
            return;
        }

        this.setState({
          ...this.state,
          formFields: newformFields,
            videoFile,
            videoSizeValid: true,
        },()=>{
            this.validateForm();
        })
    };

    onToggleContentType = (contentType) => {
        let newformFields = JSON.parse(JSON.stringify(this.state.formFields));

        for (const key in newformFields) {
            if (key === contentType) {
                newformFields[contentType].enabled = !newformFields[contentType].enabled;
            }
        }

        this.setState({
            ...this.state,
            formFields: newformFields
        },()=>{
            this.validateForm();
        })
    };

    prepareFinishData = ()=>{
        const { poiName, formFields, audioFile, videoFile, videoHeight, videoWidth } = this.state;
        const {text, audio, video} = formFields;

        if(this.state.mode==='edit'){
             let data = {};
             if(this.state.touchedFields.name) data['name'] = poiName;
             if(this.state.touchedFields.text) data['text'] = text;
             if(this.state.touchedFields.audio) data['audio'] = {enabled:audio.enabled,file:audioFile};
             if(this.state.touchedFields.video) data['video'] = {enabled:video.enabled,file:videoFile,videoHeight:videoHeight,videoWidth:videoWidth };
             return data;
        }else{
            return {name:poiName, text, audio:{enabled:audio.enabled,file:audioFile}, video:{enabled:video.enabled,file:videoFile,videoHeight:videoHeight,videoWidth:videoWidth }}
        }
    };

    render() {
        const {title, confirmButtonText, onCancel, onFinish} = this.props;
        const { poiName, formFields, formValid, audioSizeValid, videoSizeValid} = this.state;
        const {text, audio, video} = formFields;
        
        return (
            <Modal title={title} confirmButtonText={confirmButtonText} onCancel={onCancel} onFinish={() => onFinish(this.prepareFinishData())} disabled={!formValid}>
                <Input margin='0 0 10px 0' type='text' labelText='poi name' placeholderText='Enter the POI name...' value={poiName} readOnly={false} fieldId='name' onTextInput={this.poiNameChangedHandler} errorMessageText='' autoFocus={true} />
                <Frame title='text' icon={<TextIcon width='16px' margin='0 0 0 10px' fill='#D0FEED' />} padding='39px 8px 8px 8px' borderColor='#D0FEED' textColor='#D0FEED'>
                    <OptionsWrapper>
                        <Switch checked={text.enabled} onSwitch={() => this.onToggleContentType('text')} />
                    </OptionsWrapper>
                    <Input type='textarea' labelText='description' placeholderText='Describe this point of interest...' value={text.description} readOnly={false} disabled={!text.enabled} fieldId='description' onTextInput={this.textChangedHandler} errorMessageText='' autoFocus={false} />
                </Frame>

                <Frame title='audio' icon={<AudioIcon width='16px' margin='0 0 0 10px' fill='#D0FEED' />} height='95px' borderColor='#D0FEED' textColor='#D0FEED'>
                    <OptionsWrapper>
                        {audio.file
                            ?   <>
                                    <HidenFileInput id='reupload-audio' type='file' accept='audio/*' onChange={this.onChooseAudioHandler} />
                                    <ReuploadButton htmlFor='reupload-audio'>Reupload</ReuploadButton>
                                </>
                            :   null
                        }
                        <Switch checked={audio.enabled} onSwitch={() => this.onToggleContentType('audio')} />
                    </OptionsWrapper>

                    {!audioSizeValid ? <MessageLabel>File size too large! Max: 6MB</MessageLabel> : null}
                    <FrameContentWrapper>
                        {audio.file
                            ?   <MediaWrapper>
                                    <MediaLabel>{audio.originalFileName}</MediaLabel>
                                    <StyledAudio controls src={audio.file} />
                                </MediaWrapper>
                            :   <>
                                    <HidenFileInput id='upload-audio' type='file' accept='audio/*' onChange={this.onChooseAudioHandler} />
                                    <UploadButton htmlFor='upload-audio' disabled={!audio.enabled}>Choose audio</UploadButton>
                                </>
                        }
                    </FrameContentWrapper>

                </Frame>
                
                <Frame title='video' icon={<VideoIcon width='20px' margin='0 0 0 10px' fill='#D0FEED' />} height='307px' borderColor='#D0FEED' textColor='#D0FEED'>
                    <OptionsWrapper>
                        {video.file
                            ?   <>
                                    <HidenFileInput id='reupload-video' type='file' accept='video/*' onChange={this.onChooseVideoHandler} />
                                    <ReuploadButton htmlFor='reupload-video'>Reupload</ReuploadButton>
                                </>
                            :   null
                        }
                        <Switch checked={video.enabled} onSwitch={() => this.onToggleContentType('video')} />
                    </OptionsWrapper>

                    {!videoSizeValid ? <MessageLabel>File size too large! Max: 20MB</MessageLabel> : null}
                    <FrameContentWrapper>
                        {video.file
                            ?   <MediaWrapper>
                                    <MediaLabel>{video.originalFileName}</MediaLabel>
                                    <StyledVideo controls src={video.file} onLoadedMetadata={this.handleVideoMetadata}/>
                                </MediaWrapper>
                            :   <>
                                    <HidenFileInput id='upload-video' type='file' accept='video/*' onChange={this.onChooseVideoHandler} />
                                    <UploadButton htmlFor='upload-video' disabled={!video.enabled}>Choose video</UploadButton>
                                </>
                        }
                    </FrameContentWrapper>

                </Frame>
            </Modal>
        )
    }
}

export default PoiManager;

// [
//     {
//         "id": "5e8dc1408eb65a5944dd1bf5",
//         "name": "test",
//         "text": {
//             "description": "testing"
//         },
//         "video": {
//             "videoFile": "/poi/e325c76ba207efa4b3ccf960bf7fb523",
//             "videoFilename": "Lab Case 2 Animated Camera 2020.03.02 - 16.17.48.11.mp4"
//         },
//         "audio": {
//             "audioFile": "/poi/3fe3f3d45c3710f5aa77850275effd81",
//             "audioFilename": "Audio Example.mp3"
//         }
//     }
// ]