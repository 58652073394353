import React from 'react';
import styled, { keyframes } from 'styled-components';

const squareSpin = keyframes`
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
                transform: perspective(100px) rotateX(180deg) rotateY(0); }
    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
                transform: perspective(100px) rotateX(180deg) rotateY(180deg); }
    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
                transform: perspective(100px) rotateX(0) rotateY(180deg); }
    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
                transform: perspective(100px) rotateX(0) rotateY(0); }
`;

const SquareSpin = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    & > div {
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
        width: 50px;
        height: 50px;
        background: #2D7865;
        -webkit-animation: ${squareSpin} 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
                animation: ${squareSpin} 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
    }
`;

const LoadingSpinner = () => {
    return(
        <SquareSpin>
            <div />
        </SquareSpin>
    );
};

export default LoadingSpinner;