import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
${({margin="0",minWidth="249px",height="74px",fontSize="30px",disabled=false})=>`
  margin:${margin};
  min-width: ${minWidth};
  height: ${height};
  line-height: ${height};
  font-size: ${fontSize};
  background-color:${disabled?'#ccc':'#59c9e5'};
  cursor:${disabled?'not-allowed':'pointer'};
`}
    padding: 0 20px;
  border-radius: 37px;
  border: none;
  outline: none;
  color: #ffffff;
`;

const LoginButton = ({disabled, margin,minWidth, height,fontSize, children="Login Button", onClickHandler=()=>{alert("Clicked")}}) => <StyledButton disabled={disabled} margin={margin} minWidth={minWidth} height={height} fontSize={fontSize} onClick={() => onClickHandler()}>{children}</StyledButton>;

export default LoginButton;
