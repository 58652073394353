import React, { Component } from 'react';
import {AxiosInstance} from '../Communication/CommApi';
import { NavContext } from './NavigationContext';

const LocationContext = React.createContext(null);

class LocationContextProvider extends Component {
    //function for getting all locations
    getAllLocations = async ()=>{
        try {
            const response = await AxiosInstance().get('/api/locations');

            this.setState({
                ...this.state,
                locations: [...response.data],
                breadCrumbs: [],
                portals:[],
                pois:[],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't load locations.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                breadCrumbs: [],
                portals:[],
                pois:[],
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }       
    };

    getLocation = async(data)=>{
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
            if(currentItem && currentItem.parentId===data.parentId) return;
            let config = {                      
                params: {
                    locationId:data.locationId,
                    parentId:data.parentId
                }
            };
            const bcItemIndex = this.state.breadCrumbs.findIndex(bc=>bc.parentId===data.parentId);
            let bcLocation = [...this.state.breadCrumbs];
            if(bcItemIndex<0){
                bcLocation.push({locationId:data.locationId, parentId:data.parentId,name:data.name});
            }else{
                bcLocation.splice(bcItemIndex+1);
            }

            this.context.onItemChange({id:"image360Viewer", pageName:"360º Photo View"});
            AxiosInstance().get('/api/locations',config)
                .then(response=>{
                    this.setState({
                        ...this.state,
                        data: {...response.data.location},
                        portals: [...response.data.portals],
                        pois: [...response.data.pois],
                        dataLoading: false
                    })
                })
                .catch(error=>{
                    let errorMessage;
                    if (error.response) {
                        errorMessage = "Something went wrong, couldn't load location data.";
                    } else {
                        errorMessage = 'Communication problem, please check your connection or try again later.';
                    }

                    this.setState({
                        ...this.state,
                        hasError:true,
                        errorMessage,
                        dataLoading:false
                    })
                });
                this.setState({
                    ...this.state,
                    breadCrumbs:bcLocation,
                    dataLoading:true
                })
    };

    getPortal = async(data)=>{
        const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
        let config = {                      
            params:{
                locationId:currentItem.locationId,
                parentId:data.id
            }
        };
        const bcLocation = [...this.state.breadCrumbs];
        bcLocation.push({locationId:currentItem.locationId, parentId:data.id,name:data.name});
        this.context.onItemChange({id:"image360Viewer", pageName:"360º Photo View"});
        AxiosInstance().get('/api/locations',config)
            .then(response=>{
                this.setState({
                    ...this.state,
                    data: {...response.data.location},
                    portals:[...response.data.portals],
                    pois:[...response.data.pois],
                    dataLoading:false
                })
            })
            .catch(error=>{
                let errorMessage;
                if (error.response) {
                    errorMessage = "Something went wrong, couldn't load portal data.";
                } else {
                    errorMessage = 'Communication problem, please check your connection or try again later.';
                }
                
                this.setState({
                    ...this.state,
                    hasError:true,
                    errorMessage,
                    dataLoading:false
                })
            });
            this.setState({
                ...this.state,
                breadCrumbs:bcLocation,
                dataLoading:true
            })
};

    insertLocation = async (location)=>{
        try {
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            };
            const response = await AxiosInstance().post('/api/locations',location,config);

            this.setState({
                ...this.state,
                locations: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't create a location.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
        
    };

    updateLocation = async (location)=>{
        try {
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            };
            const response = await AxiosInstance().post('/api/locations/update-location',location,config);

            this.setState({
                ...this.state,
                locations: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't edit location.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
        
    };

    deleteLocation = async (locationId)=>{
        try {
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            };
            const response = await AxiosInstance().post('/api/locations/delete-location',{id:locationId},config);

            this.setState({
                ...this.state,
                locations: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't delete a location.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    };

    insertPortal = async(portal)=>{
        try {
            let portalData={...portal};
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
            portalData.locationId = currentItem.locationId;
            portalData.parentId = currentItem.parentId;
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            };
            const response = await AxiosInstance().post('/api/locations/portal',portalData,config);

            this.setState({
                ...this.state,
                portals: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't create a portal.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    };

    updatePortal = async(portal)=>{
        try {
            let portalData={...portal};
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
            portalData.locationId = currentItem.locationId;
            portalData.parentId = currentItem.parentId;
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            };
            const response = await AxiosInstance().post('/api/locations/update-portal',portalData,config);

            this.setState({
                ...this.state,
                portals: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't edit portal.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    };

    updatePortalPosition = async(portal)=>{
        try {
            let portalData={...portal};
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
            portalData.locationId = currentItem.locationId;
            portalData.parentId = currentItem.parentId;
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            };
            const response = await AxiosInstance().post('/api/locations/update-portal-position',portalData,config);

            this.setState({
                ...this.state,
                portals: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't update portal position.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    };

    deletePortal = async(portal)=>{
        try {
            let portalData={};
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
            portalData.locationId = currentItem.locationId;
            portalData.parentId = currentItem.parentId;
            portalData.portalId = portal;

            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            };
            const response = await AxiosInstance().post('/api/locations/delete-portal',portalData,config);

            this.setState({
                ...this.state,
                portals: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't delete portal.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    };

    insertPoi = async(poi)=>{
        try {

            let poiData={...poi};
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
            poiData.locationId = currentItem.locationId;
            poiData.parentId = currentItem.parentId;
            const userToken = localStorage.getItem('user-token');

            const formData = new FormData();
            formData.append('locationId',currentItem.locationId);
            formData.append('parentId',currentItem.parentId);
            formData.append('name',poiData.name);
            formData.append('textEnabled',poiData.text.enabled.toString());
            if(poiData.text.enabled){
                formData.append('description',poiData.text.description);
            }
            formData.append('audioEnabled',poiData.audio.enabled.toString());
            if(poiData.audio.enabled){
                formData.append('audioFile',poiData.audio.file);
            }
            formData.append('videoEnabled',poiData.video.enabled.toString());
            if(poiData.video.enabled){
                formData.append('videoFile',poiData.video.file);
                formData.append('videoHeight',poiData.video.videoHeight);
                formData.append('videoWidth',poiData.video.videoWidth);
            }
            const config = {
                headers: {
                    'x-auth-token': userToken,
                    'content-type': 'multipart/form-data'
                }
            };

            const response = await AxiosInstance().post('/api/locations/poi',formData,config);

            this.setState({
                ...this.state,
                pois: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't create a POI.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        } 
    };

    updatePoi = async(poi)=>{
        try {
            let poiData={...poi};
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
            poiData.locationId = currentItem.locationId;
            poiData.parentId = currentItem.parentId;
            const userToken = localStorage.getItem('user-token');

            const formData = new FormData();
            formData.append('poiId',poiData.poiId);
            formData.append('locationId',poiData.locationId);
            formData.append('parentId',poiData.parentId);
            if(poiData.name)
                formData.append('name',poiData.name);
            if(poiData.text){
                formData.append('textEnabled',poiData.text.enabled.toString());
                if(poiData.text.enabled){
                    formData.append('description',poiData.text.description);
                }
            }
            if(poiData.audio){
                formData.append('audioEnabled',poiData.audio.enabled.toString());
                if(poiData.audio.enabled){
                    formData.append('audioFile',poiData.audio.file);
                }
            }
            if(poiData.video){
                formData.append('videoEnabled',poiData.video.enabled.toString());
                if(poiData.video.enabled){
                    formData.append('videoFile',poiData.video.file);
                    formData.append('videoHeight',poiData.video.videoHeight);
                    formData.append('videoWidth',poiData.video.videoWidth);
                }
            }

            let config = {
                headers:{
                    'x-auth-token': userToken,
                    'content-type': 'multipart/form-data'
                }
            };
            const response = await AxiosInstance().put('/api/locations/poi',formData,config);

            this.setState({
                ...this.state,
                pois: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't edit POI.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    };

    updatePoiPosition = async(poi)=>{
        try {
            let poiData={...poi};
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
            poiData.locationId = currentItem.locationId;
            poiData.parentId = currentItem.parentId;
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            };
            const response = await AxiosInstance().post('/api/locations/update-poi-position',poiData,config);

            this.setState({
                ...this.state,
                pois: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't update POI position.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    };

    deletePoi = async(poi)=>{
        try {
            let poiData={};
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];
            poiData.locationId = currentItem.locationId;
            poiData.parentId = currentItem.parentId;
            poiData.poiId = poi;
            const userToken = localStorage.getItem('user-token');
            let config = {
                headers:{
                    'x-auth-token': userToken
                }
            };
            const response = await AxiosInstance().post('/api/locations/delete-poi',poiData,config);

            this.setState({
                ...this.state,
                pois: [...response.data],
                dataLoading:false
            })
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = "Something went wrong, couldn't delete POI.";
            } else {
                errorMessage = 'Communication problem, please check your connection or try again later.';
            }
            
            this.setState({
                ...this.state,
                hasError:true,
                errorMessage,
                dataLoading:false
            })
        }
    };

    uploadImage=async(files,data)=>{
        try{
            const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];

            const userToken = localStorage.getItem('user-token');
            const formData = new FormData();
            formData.append('photo',files[0]);
            formData.append('locationId',currentItem.locationId);
            formData.append('parentId',currentItem.parentId);
            const config = {
                headers: {
                    'x-auth-token': userToken,
                    'content-type': 'multipart/form-data'
                }
            };
            AxiosInstance().post('/api/locations/360',formData,config)
            .then(response=>{
                this.setState({
                    ...this.state,
                    data: {...response.data.location},
                    portals:[...response.data.portals],
                    pois:[...response.data.pois],
                    dataLoading:false
                })
            })
            .catch(error=>{
                let errorMessage;
                if (error.response) {
                    errorMessage = "Something went wrong, couldn't upload image.";
                } else {
                    errorMessage = 'Communication problem, please check your connection or try again later.';
                }
                
                this.setState({
                    ...this.state,
                    hasError:true,
                    errorMessage,
                    dataLoading:false
                })
            });
            this.setState({
                ...this.state,
                dataLoading:true
            })
            
        } catch (error){
            console.log(error);
            
        }
    };

    getImage = async(image) => {
        try{

        }catch(error){
            console.log(error);
        }
    };

    handleRemoveError = () => {
        this.setState({
            ...this.state,
            hasError: false,
            errorMessage: ''
        })
    };

    state = { 
       getAllLocations:this.getAllLocations,
        getLocation:this.getLocation,
        getPortal:this.getPortal,
        insertLocation:this.insertLocation,
        updateLocation:this.updateLocation,
        deleteLocation:this.deleteLocation,
        insertPortal:this.insertPortal,
        updatePortal:this.updatePortal,
        updatePortalPosition:this.updatePortalPosition,
        deletePortal:this.deletePortal,
        insertPoi:this.insertPoi,
        updatePoi:this.updatePoi,
        updatePoiPosition:this.updatePoiPosition,
        deletePoi:this.deletePoi,
        uploadImage:this.uploadImage,
        getImage:this.getImage,
        handleRemoveError: this.handleRemoveError,
        locations:[],
        breadCrumbs:[],
        hasError:false,
        errorMessage: '',
        dataLoading:true,
        data:null,
        portals:[],
        pois:[]
    };
    
    render() {
        return (
            <LocationContext.Provider value={this.state}>
                { this.props.children }
            </LocationContext.Provider>
        );
    }
}

LocationContextProvider.contextType = NavContext;

const LocationContextConsumer = LocationContext.Consumer;

export { LocationContextProvider, LocationContext, LocationContextConsumer};