import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
    ${({ width='24', margin='0', fill='#000' }) => `
        width: ${width};
        margin: ${margin};
        fill: ${fill};
    `}
`;

const TextIcon = ({ width, margin, fill }) => {
    return (
        <Svg width={width} margin={margin} fill={fill} height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 4H0V0H24V4ZM18 8H0V12H18V8ZM21 16H0V20H21V16Z" />
        </Svg>
    )
}

export default TextIcon;
