import React from 'react';
import styled from 'styled-components';

const Cell = styled.td`
    vertical-align: middle;
    padding-left: 10px;
    &:last-child {
        padding-right: 10px;
    }
`;

const CellInner = styled.div`
    display: flex;
    align-items: center;
    min-height: 45px;
    ${({ desc }) => desc
            ? `text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: block;
                padding: 11px 0 10px 0;`
            : null
        }
    }
`;

const TableCell = ({children=null, desc})=>{
    return(
        <Cell>
            <CellInner desc={desc}>
                {children}
            </CellInner>
        </Cell>
    )
}

export default TableCell;