import React, { Component } from 'react';
import styled from 'styled-components';
import PageSelector from '../PageSelector/PageSelector';
// import GoToPage from '../GoToPage/GoToPage';
// import RowsPerPage from '../RowsPerPage/RowsPerPage';
// import { boxShadow } from '../../../../resources/style/functions';

const PaginationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    padding: 0 20px;
    font-size: 14px;
    background: #ffffff;
`

const PageInfo = styled.div`
    font-size:16px;
    color:#2d7865;
    margin:1px 20px 0 0;
`
class TablePagination extends Component {
    state={
        pageNumber:''
    }
    componentWillUnmount(){
        this.setState({
            pageNumber:''
        });
    }    
    handleChange= event =>{
        let maxPage = Math.ceil(this.props.count/this.props.rowsPerPage);
        
        if(!isNaN(event.target.value)){
            if(event.target.value>maxPage){
                this.setState({pageNumber:maxPage});
            }else{
                this.setState({pageNumber:event.target.value})
            }
        }
    }
    handleChangeShowOnPage=event=>{
        this.props.onChangeRowsPerPage(event);
        this.setState({
            pageNumber:''
        });

    }
    handleChangeConfirm=event=>{
        if(event.key==='Enter'){
            if(~~this.state.pageNumber<=0){
                this.setState({
                    pageNumber:this.props.page+1
                })
            }else{
                this.props.onChangePage(this.state.pageNumber-1);
            }
        }        
    }
    render()
    {
        const {count, onChangePage, page, rowsPerPage} = this.props;
        const maxPage = Math.ceil(count/rowsPerPage);
        return ( 
        <PaginationWrapper>
            <PageInfo>{`${page+1} of ${maxPage}`}</PageInfo>
            <PageSelector rowsPerPage={rowsPerPage} page={page} count={count} onChangePage={onChangePage}/>
        </PaginationWrapper>);
    }
}

export default TablePagination;