import React, { Component } from 'react';
import styled from 'styled-components';
import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';

import { FormManager, Form, ClickableLabel, MessageBox, LoadingSpinner } from "../../components/ui";
import { globalVariables } from '../../utility/common';

import BackgroundImage from '../../resources/img/account-settings-bg.jpg';
import IconBack from '../../resources/img/icon-back.png';
import { AuthContext } from '../../utility/Contexts/AuthContext';

const PageWrapper = styled.div`
    display: flex;
    height: calc(100vh - ${globalVariables.headerHeight});
    width: 100%;
    background-color: #ecf1eb;
    overflow-x: hidden;
`;

const SideContentWrapper = styled.div`
    position: relative;
    width: ${globalVariables.sideContentWidth};
    height: 100%;
    z-index: 500;
`;

const SideContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 65px 60px 0;
    background-color: #ffffff;
`;

const Title = styled.h2`
    font-size: 24px;
    margin-bottom: 80px;
    font-weight: normal;
    color: #2D7865;
`;

const MainContentWrapper = styled.div`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - ${globalVariables.sideContentWidth});
    background: url(${BackgroundImage}) center / cover no-repeat;
`;

const BackIcon = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    border: 1px solid #2D7865;
    border-radius: 50%;
    cursor: pointer;
`

const changePasswordFields = [
    {
        id: 'password',
        inputType: 'password',
        value:'',
        label: 'password',
        autofocus: true,
        validation:{
            required:true,
            minLength:8,
            maxLength:50
        }
    },
    {
        id: 'repeatPassword',
        inputType: 'password',
        value:'',
        label: 'repeat password',
        validation:{
            required:true,
            minLength:8,
            maxLength:50
        }
    },
]

const accountFields = [
    {
        id: 'name',
        inputType: 'text',
        value:'',
        label: 'name',
        readOnly: true,
    },
    {
        id: 'surname',
        inputType: 'text',
        value:'',
        label: 'surname',
        readOnly: true,
    },
    {
        id: 'institution',
        inputType: 'text',
        value:'',
        label: 'institution',
        readOnly: true,
    },
    {
        id: 'email',
        inputType: 'text',
        value:'',
        label: 'email',
        readOnly: true,
    },
]
class AccountSettings extends Component {
    state = {
        formActive: false,
    }

    accountDetails = [];

    componentWillMount(){
        const newAccountFields = JSON.parse(JSON.stringify(accountFields));
        newAccountFields.forEach(field => {
            if(this.context.userData.hasOwnProperty(field.id)){
                field.value = this.context.userData[field.id];
            }
        });
        this.accountDetails = [...newAccountFields];
    }

    handleOpenForm = () => {
        this.setState({
            ...this.state,
            formActive: true
        })
    }

    handleCloseForm = () => {
        this.setState({
            ...this.state,
            formActive: false
        })
    }

    handleChangePassword = (fields) => {
        const password = fields[0];
        const repeatPassword = fields[1];
        if(password.value === repeatPassword.value){
            this.context.resetPassword(password.value)
        }
    }
    
    render () {
        const { formActive } = this.state;
        const { dataLoading, hasError, errorMessage, handleRemoveError } = this.context;
        
        return (
            <PageWrapper>
                <SideContentWrapper>
                    <SideContent>
                        <Title>My Account</Title>
                        <Form margin='0 0 40px 0' formFields={this.accountDetails} />
                        <ClickableLabel fontSize='20px' underline onClickHandler={() => this.handleOpenForm()}>Change password</ClickableLabel>
                    </SideContent>
                </SideContentWrapper>

                <MainContentWrapper>
                    <NavContextConsumer>
                        {({ onItemChange }) =>
                            <BackIcon onClick={() => onItemChange({id: 'locationViewer', pageName: 'Locations View'})}>
                                <img src={IconBack} alt=""/>
                            </BackIcon>
                        }
                    </NavContextConsumer>
                </MainContentWrapper>

                {formActive
                    ?   <FormManager title="CHANGE PASSWORD" confirmButtonText='Confirm' onCancel={this.handleCloseForm} onFinish={this.handleChangePassword} fields={JSON.parse(JSON.stringify(changePasswordFields))}/>
                    :   null
                }
                {hasError ? <MessageBox title="Error" confirmButtonText='Close' onConfirm={handleRemoveError}>{errorMessage}</MessageBox> : null}
                {dataLoading ? <LoadingSpinner /> : null}
                
            </PageWrapper>
        );
    };
};

AccountSettings.contextType = AuthContext;

export default AccountSettings;