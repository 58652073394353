import React, { Component } from 'react';
import styled from 'styled-components';

import DataTable from "../../components/ui/DataTable/DataTable";
import { Input, Button, RowActions, Modal, Form, FormManager, MessageBox  } from '../../components/ui';
import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';
import { LocationContext } from '../../utility/Contexts/LocationContext';
import Map from '../../components/common/Map';
import LoadingSpinner from '../../components/ui/LoadingSpinner/LoadingSpinner';

import {checkValidity,updateObject} from '../../utility/utility';
import { globalVariables } from '../../utility/common';

import IconSearch from "../../resources/img/icon-search.png";
import IconEdit from "../../resources/img/icon-edit.png";
import IconDelete from "../../resources/img/icon-delete.png";
import IconMap from "../../resources/img/icon-map.png";
import IconCloseMap from "../../resources/img/icon-close-map.png";

const PageWrapper = styled.div`
    display: flex;
    height: calc(100vh - ${globalVariables.headerHeight});
    width: 100%;
    background-color: #ecf1eb;
    overflow-x: hidden;
`;

const SideContentWrapper = styled.div`
    position: relative;
    ${({ mainContentShown })=>`
        width: ${mainContentShown ? globalVariables.sideContentWidth : '100%'};    
    `}
    height: 100%;
    z-index: 500;
`;

const SideContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 13px 8px 0;
    background-color: #ecf1eb;
    overflow-x: hidden;
`;

const CrudWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const MainContentWrapper = styled.div`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - ${globalVariables.sideContentWidth});
    background-color: #ffffff;
`;

const ShowMapButton = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: -130px;
    width: 180px;
    height: 60px;
    font-size: 18px;
    color: #ffffff;
    transform: translate(0, -50%);
    background-color: #2d7865;
    border-radius: 40px 0 0 40px;
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    transition: all 0.2s ease-in-out;
    z-index: 9999;
    cursor: pointer;
    &:hover {
        transform: translate(-130px, -50%);
    }
`;

const HideMapButton = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: -50px;
    width: 180px;
    height: 60px;
    font-size: 18px;
    color: #ffffff;
    transform: translate(0, -50%);
    background-color: #2d7865;
    border-radius: 0 40px 40px 0;
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    transition: all 0.2s ease-in-out;
    z-index: -1;
    cursor: pointer;
    &:hover {
        transform: translate(130px, -50%);
    }
`

const Icon = styled.img`
    width: 24px;
    ${({ margin = '0 30px 0 0'}) =>`
        margin: ${margin};
    `}
`;

const CityCol = styled.col`
    @media (max-width: 1579px) {
        width: 160px !important;
    }
`;

const DescCol = styled.col`
    @media (max-width: 1579px) {
        width: 350px !important;
    }
`;

const tableFieldsShort= [
    {
        fieldId:'id',
        displayName: 'ID',
        isDefaultSort:true,
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'name',
        displayName: 'Location Name',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'city',
        displayName: 'City',                    
        isSortable:false,
        isFilterable:true
    },
    {
        fieldId:'date',
        displayName: 'Date',                    
        isSortable:true,
        isFilterable:true
    }
];

const tableFieldsFull= [
    {
        fieldId:'id',
        displayName: 'ID',
        isDefaultSort:true,
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'name',
        displayName: 'Location Name',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'city',
        displayName: 'City',                    
        isSortable:false,
        isFilterable:true
    },
    {
        fieldId:'latitude',
        displayName: 'Latitude',                    
        isSortable:false,
        isFilterable:true
    },
    {
        fieldId:'longitude',
        displayName: 'Longitude',                    
        isSortable:false,
        isFilterable:true
    },
    {
        fieldId:'date',
        displayName: 'Date',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'description',
        displayName: 'Description',                    
        isSortable:true,
        isFilterable:true
    },
    {
        fieldId:'actions',
        displayName: 'Actions',                    
        isSortable:false,
        isFilterable:true
    }
];

const formFields=[
    {
        id: 'name',
        inputType: 'text',
        value:'',
        label: 'location name',
        autofocus: true,
        validation:{
            required:true,
            minLength:2,
            maxLength:50
        }
    },
    {
        id: 'city',
        inputType: 'text',
        value:'',
        label: 'city',
        validation:{
            required:true,
            minLength:2,
            maxLength:50
        }
    },
    {
        id: 'latitude',
        inputType: 'number',
        value:'',
        label: 'latitude',
        validation:{
            required:true,                    
        }
    },
    {
        id: 'longitude',
        inputType: 'number',
        value:'',
        label: 'longitude',
        validation:{
            required:true,                    
        }
    },
    {
        id: 'description',
        inputType: 'textarea',
        value:'',
        label: 'description',
        validation:{
            maxLength:1024
        }
    },
]

const searchHelper = (locations,search)=>{
    if(!locations) return [];
    if(locations.length===0) return locations;
    if(search.length===0) return locations;
    const searchData = locations.filter(d=>d.city.toLowerCase().includes(search.toLowerCase()));
    return searchData
}
class LocationViewer extends Component {

    async componentWillMount(){
      await this.context.getAllLocations();
    }

    //communtication code goes here
    state = {
        activeForm: null,
        selectedLocation: "",
        mainContentShown: false,
        search:""
    }   
    
    handleOpenForm = ()=>{
        this.setState({
            ...this.state,
            activeForm : <FormManager title="ADD LOCATION" onCancel={this.handleCloseForm} onFinish={this.handleFormFinish} fields={JSON.parse(JSON.stringify(formFields))}/>
        })                
    }

    handleOpenEditForm = ()=>{
        if(this.state.selectedLocation.length===0) return;
        const locationIndex = this.context.locations.findIndex(l=>l.id === this.state.selectedLocation);

        if(locationIndex<0) return;
        const location = this.context.locations[locationIndex];
        const locationFields= JSON.parse(JSON.stringify(formFields));

        locationFields.forEach(field => {
            if(location.hasOwnProperty(field.id)){
                field.value = location[field.id];
            }
        });
        this.setState({
            ...this.state,
            selectedLocation:location.id,
            activeForm : <FormManager title="EDIT LOCATION" edit onCancel={this.handleCloseForm} onFinish={this.handleEditFormFinish} fields={JSON.parse(JSON.stringify(locationFields))}/>
        })
                
    }
    handleCloseForm = ()=>{
        
        this.setState({
            ...this.state,
            activeForm:null,
            selectedLocation:""
        })
    }
    
    handleOpenMessageBox = ()=>{
        if(this.state.selectedLocation.length===0) return;
        this.setState({
            ...this.state,
            activeForm:<MessageBox onCancel={this.handleCloseMessageBox} onConfirm={this.handleConfirmMessageBox} title="Confirm Delete" confirmButtonText='Delete'>Are you sure that you wish to delete this location?</MessageBox>
        })
    }

    handleFormFinish = async(location) => {
        const data = {};
        location.forEach(loc => {
            if(loc.id==="description")
            {    
                if(loc.value!=='')
                data[loc.id] = loc.value;
            }
            else{
                data[loc.id] = loc.value;
            }
        });
        
        await this.context.insertLocation(data);
        this.handleCloseForm();
    }

    handleEditFormFinish = async(location) =>{
        const data = {};
        location.forEach(loc => {
            if(loc.id==="description")
            {    
                if(loc.value!=='')
                data[loc.id] = loc.value;
            }
            else{
                data[loc.id] = loc.value;
            }
        });
        data.id=this.state.selectedLocation;
        await this.context.updateLocation(data);
        this.handleCloseForm();
    }

    handleConfirmMessageBox = ()=>{
        this.context.deleteLocation(this.state.selectedLocation);
        this.setState({
            ...this.state,
            activeForm:null,
            selectedLocation:""
        })
    }

    handleCloseMessageBox = ()=>{
        this.setState({
            ...this.state,
            activeForm:null
        })
    }

    handleTableRowSelected = (id)=>{
        this.setState({
            ...this.state,
            selectedLocation:id
        })
    }

    handleSearch = (e) =>{
        
        this.setState({
            ...this.state,
            search:e.currentTarget.value
        })
    }

    handleToggleMap = (toggle) => {
        this.setState({
            ...this.state,
            mainContentShown:toggle
        })
    }

    render() {
        const {activeForm, mainContentShown, selectedLocation, search} = this.state;
        const {dataLoading, locations, getLocation, hasError, errorMessage, handleRemoveError} = this.context;
        
        const currentLocation = locations.filter(loc => loc.id === selectedLocation)[0];
        
        const tableColumnWidths =   <colgroup>
                                        {!mainContentShown
                                            ?   <React.Fragment>
                                                    <col style={{width: "69px"}} />
                                                    <col style={{width: "100%"}} />
                                                    <CityCol style={{width: "240px"}} />
                                                    <col style={{width: "120px"}} />
                                                    <col style={{width: "120px"}} />
                                                    <col style={{width: "110px"}} />
                                                    <DescCol style={{width: "550px"}} />
                                                    <col style={{width: "200px"}} />
                                                </React.Fragment>
                                            :   <React.Fragment>
                                                    <col style={{width: "60px"}} />
                                                    <col style={{width: "100%"}} />
                                                    <col style={{width: "152px"}} />
                                                    <col style={{width: "110px"}} />
                                                </React.Fragment>}
                                    </colgroup>;
        
        if(dataLoading) return <LoadingSpinner/>

        const dataToShow = searchHelper(locations,search);                                      
        return (
            <PageWrapper>
                <SideContentWrapper mainContentShown={mainContentShown}>
                    <SideContent>
                        <Input placeholderText="Search by City" icon={IconSearch} margin="0 0 14px 0" value={search} onTextInput={this.handleSearch}/>
                        <CrudWrapper>
                            <Button margin="0 20px 10px 20px" onClickHandler={() => this.handleOpenForm()}>ADD LOCATION</Button>
                            <Button margin="0 20px 10px 0" disabled={selectedLocation.length === 0} onClickHandler={() => this.handleOpenEditForm()}>EDIT</Button>
                            <Button margin="0 0 10px 0" disabled={selectedLocation.length === 0} onClickHandler={() => this.handleOpenMessageBox()}>DELETE</Button>
                        </CrudWrapper>
    
                        <DataTable columnWidths={tableColumnWidths}
                                    maxRows={10}
                                    tableFields={mainContentShown ? tableFieldsShort : tableFieldsFull}
                                    data={dataToShow}
                                    onDoubleClickHandler={()=>{}}
                                    onClickHandler={this.handleTableRowSelected}
                                    activeRow={selectedLocation}
                                    primaryKey='id'
                                    actionButton = {(e)=><RowActions onClick={()=>getLocation(e)}  actionButtonText={"ADD 360 PHOTO"}/>}
                                    />
                    </SideContent>
                        {mainContentShown
                            ?   <HideMapButton onClick={()=>this.handleToggleMap(false)}>
                                    Hide map
                                    <Icon src={IconCloseMap} alt="" margin='0 0 0 30px' />
                                </HideMapButton>
                            :   <ShowMapButton onClick={()=>this.handleToggleMap(true)}>
                                    <Icon src={IconMap} alt="" />
                                    Show map
                                </ShowMapButton>
                        }
                </SideContentWrapper>
                {mainContentShown
                    ?   <MainContentWrapper>
                            <Map onAdd360={()=>getLocation({locationId: selectedLocation, parentId: selectedLocation, name: currentLocation.name})} onClick={this.handleTableRowSelected} selectedMarker={selectedLocation} locations={dataToShow} onDelete={this.handleOpenMessageBox} />
                        </MainContentWrapper>
                    :   null
                }                
                {activeForm}
                {hasError ? <MessageBox title="Error" confirmButtonText='Close' onConfirm={handleRemoveError}>{errorMessage}</MessageBox> : null}
                {dataLoading ? <LoadingSpinner /> : null}
            </PageWrapper>
        );
    }
}

LocationViewer.contextType = LocationContext;

export default LocationViewer;