import React from 'react';
import styled from 'styled-components';

import { NavContextConsumer } from "../../utility/Contexts/NavigationContext";
import { globalVariables } from "../../utility/common";
import SideBackgroundImage from '../../resources/img/about-institute-side.jpg'
import MainBackgroundImage from '../../resources/img/institute.png'
import IconBack from '../../resources/img/icon-back.png';

const PageWrapper = styled.div`
    display: flex;
    height: calc(100vh - ${globalVariables.headerHeight});
    width: 100%;
    background-color: #ecf1eb;
    overflow-x: hidden;
`;

const SideContentWrapper = styled.div`
    position: relative;
    width: ${globalVariables.sideContentWidth};
    height: 100%;
    background: url(${SideBackgroundImage}) center / cover no-repeat;
    z-index: 500;
`;

const MainContentWrapper = styled.div`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: calc(100% - ${globalVariables.sideContentWidth});
    padding: 0 40px;
    background: url(${MainBackgroundImage}) bottom 130px right 50% / 50% no-repeat #ffffff;
`;

const TextContainer = styled.p`
    width: 980px;
    margin-top: 100px;
    font-size: 20px;
    text-align: justify;
    color: #2D7865;
`

const BackIcon = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    border: 1px solid #2D7865;
    border-radius: 50%;
    cursor: pointer;
`

const AboutInstitute = () => (
    <PageWrapper>
        <SideContentWrapper />

        <MainContentWrapper>
            <TextContainer>
            Institute of Lowland Forestry and Environment (ILFE) is the part of University of Novi Sad. Institute was established in 1958. ILFE has 35 employees, from which 18 PhDs in different disciplines (forestry, biology, ecology and biochemistry). ILFE is dealing with biological, ecological and environmental aspects of forest ecosystems. ILFE has a very close cooperation with the governmental institutions, forest public enterprises, as well as with private sector. ILFE has long tradition in establishing new forest areas (since its initial founding) and experience in advanced scientific methods, including state-of-the art GIS applications, such as 3D maping and simulations. ilfe.org
            </TextContainer>
            <NavContextConsumer>
                {({ onItemChange }) =>
                    <BackIcon onClick={() => onItemChange({id: 'locationViewer', pageName: 'Locations View'})}>
                        <img src={IconBack} alt=""/>
                    </BackIcon>
                }
            </NavContextConsumer>
        </MainContentWrapper>
        
    </PageWrapper>
);

export default AboutInstitute;