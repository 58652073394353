import React, { Component } from 'react';
import {AxiosInstance} from '../Communication/CommApi';
import { NavContext } from './NavigationContext';
import jwt from "jsonwebtoken";


const AuthContext = React.createContext();

class AuthContextProvider extends Component {      

    login = async ({email,password})=>{
        this.setState({
            ...this.state,
            dataLoading: true
        })
     try{
         //todo implement validation
         if(email.length===0 || password.length===0){
             this.setState({
                 ...this.state,
                 hasError:true,
                 dataLoading: false
             })
         }
        //send data to server
        let response = await AxiosInstance().post('/api/login',{email,password});
        //wait for the response

        if(!response) return; //add error msg
        //store token in localstorage
        localStorage.setItem("user-token",response.headers["x-auth-token"])

        const decoded = jwt.decode(localStorage.getItem("user-token"), {complete: true});
        
        //change state to loggedin=true
        
            this.setState({
                ...this.state,
                hasError:false,
                dataLoading: false,
                userData:{...response.data},
                loggedin:true,
                isAdmin: decoded.payload.isAdmin
            })
     }
     catch(error){
         let errorMessage;
         if (error.response) {
            errorMessage = 'Login failed, please check your email and password.';
          } else {
            errorMessage = 'Communication problem, please check your connection or try again later.';
          }
         
        this.setState({
            ...this.state,
            hasError:true,
            errorMessage,
            dataLoading: false
        })
     }
        
    }

    logout=()=>{
        //remove token from localstorage
        localStorage.clear();
        this.context.handleLogout();
        //change state loggedin=false
        this.setState({
            ...this.state,
            loggedin:false
        })
    }

    resetPassword = async(password) =>{
        this.setState({
            ...this.state,
            dataLoading: true
        })

        const userToken = localStorage.getItem('user-token');
       
        const config = {
            headers: {
                'x-auth-token': userToken
            }
        }
        const response = await AxiosInstance().post('/api/user/reset-password',{password},config)
            .catch(error => {
                    let errorMessage;
                    if (error.response) {
                        errorMessage = `${error.response.data}`;
                    } else {
                        errorMessage = 'Communication problem, please check your connection or try again later.';
                    }
                    
                    this.setState({
                        ...this.state,
                        hasError:true,
                        errorMessage,
                        dataLoading: false
                    })
                }
            );
        if(!response) return;
        this.logout();
    }

    forgotPassword = async(email)=>{

        const response = await AxiosInstance().post('/api/user/request-password-reset',{email})
            .catch(error => {
                    let errorMessage;
                    if (error.response) {
                        errorMessage = `${error.response.data}`;
                    } else {
                        errorMessage = 'Communication problem, please check your connection or try again later.';
                    }
                    
                    this.setState({
                        ...this.state,
                        hasError:true,
                        errorMessage,
                        dataLoading: false
                    })
                }
            );

        if(!response) return;
        this.logout();
    }

    handleRemoveError = () => {
        this.setState({
            ...this.state,
            hasError: false,
            errorMessage: ''
        })
    }

    state = { 
       loggedin:false,
       login:this.login,
       logout:this.logout,
       resetPassword:this.resetPassword,
       forgotPassword:this.forgotPassword,
       handleRemoveError:this.handleRemoveError,
       userData:null,
       isAdmin: null,
       hasError:false,
       dataLoading: false,
       errorMessage: ''
    }
    
    render() {
        return (
            <AuthContext.Provider value={this.state}>
                { this.props.children }
            </AuthContext.Provider>
        );
    }
}
AuthContextProvider.contextType = NavContext;
const AuthContextConsumer = AuthContext.Consumer;

export { AuthContextProvider, AuthContext, AuthContextConsumer};